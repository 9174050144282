import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';

// PACKAGES
import { useLocation, useNavigate } from 'react-router-dom';
import { Accordion, Dropdown, Spinner } from 'react-bootstrap';

// COMPONENTS
import TitleSection from '../../components/TitleSection/TitleSection';

// UTILS
import { convertToKebabCase, guid } from '../../libs/utils';
import CroppedImage from '../../components/_Helpers/CroppedImage';
import SvgMask from '../../components/_Helpers/SvgMask';
import DelayedFadeIn from '../../components/_Helpers/DelayedFadeIn';

// TYPES
import apibridge from '../../apibridge';
import { SortOptionsType } from '../../components/TitleSection/SortDropdown';
import { BookListSeries, BookListStage } from '../../api/models';

const StudentAssignBooks: FC = () => {
	const location = useLocation();
	const dispatch = useDispatch();

	const { state } = location;
	const { selectedStudents, classId } = state;

	const [bookStages, setBookStages] = useState<BookListStage[]>([]);
	const [dropdownStages, setDropdownStages] = useState<SortOptionsType[]>([]);
	const [stageIds, setStageIds] = useState<string[]>([]);
	const [seriesIds, setSeriesIds] = useState<string[]>([]);
	const [bookIds, setBookIds] = useState<string[]>([]);
	const [loading, setLoading] = useState(true);
	const [searchText, setSearchText] = useState('');
	const [selectedBookIds, setSelectedBookIds] = useState<string[]>([]);

	const navigate = useNavigate();

	const getBookStages = async () => {
		setLoading(true);

		const response = await apibridge.getBookList();
		if (response && response.data && !response.data.isError && response.data.result?.stages) {
			let tempStages = [];

			for (const stage of response.data.result.stages) {
				if (!stage.id || !stage.name) continue;

				tempStages.push({
					value: stage.id,
					label: stage.name
				});
			}

			setBookStages(response.data.result.stages || []);
			setDropdownStages(tempStages);
		}

		setLoading(false);
	};

	useEffect(() => {
		getBookStages();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	/**
	 * Handler for clicking on a stage in the dropdown menu
	 *
	 * @param stage The stage that was clicked
	 */
	const handleClickStage = (stage: BookListStage) => {
		const stageId = stage.id || '';

		// Get the IDs of the series from the stage
		const seriesFromStage: string[] = stage?.serieses?.map((series) => series.id || '') || [];

		// Remove any series from the selected series IDs that are not in this stage
		const newSeriesIds = seriesIds.filter((id) => !seriesFromStage.includes(id));
		setSeriesIds(newSeriesIds);

		// Create an array of book IDs from all the series in the stage
		let booksFromStageSeries: string[] = [];

		for (const series of stage.serieses || []) {
			booksFromStageSeries = [...booksFromStageSeries, ...(series.books?.map((book) => book.id || '') || [])];
		}

		// Remove any book IDs from the selected book IDs that are not in the books from the selected stage
		const newBookIds = bookIds.filter((id) => !booksFromStageSeries.includes(id));
		setBookIds(newBookIds);

		// If the stage ID is already in the selected stage IDs, remove it and remove the books from the selected books as well
		if (stageIds.includes(stageId)) {
			setStageIds(stageIds.filter((id) => id !== stageId));

			setSelectedBookIds(selectedBookIds.filter((id) => !booksFromStageSeries.includes(id)));
			// Otherwise, add the stage ID to the selected stage IDs and add the books from the stage to the selected book IDs
		} else {
			setStageIds([...stageIds, stageId]);

			setSelectedBookIds((prev) => [...prev, ...booksFromStageSeries.filter((id) => !prev.includes(id))]);
		}
	};

	/**
	 * Handler for clicking on a series in the dropdown menu
	 *
	 * @param series The series that was clicked
	 * @param stageId The ID of the stage that the series belongs to
	 */
	const handleClickSeries = (series: BookListSeries, stageId: string) => {
		// Get the IDs of the books from the series
		const booksFromSeries: string[] = series?.books?.map((book) => book.id || '') || [];

		// If the stage ID is already in the selected stage IDs, remove it
		if (stageIds.includes(stageId)) {
			setStageIds(stageIds.filter((id) => id !== stageId));
		}

		// Clear books on selecting series
		const newBookIds = bookIds.filter((id) => !booksFromSeries.includes(id));
		setBookIds(newBookIds);

		// If the series ID is already in the selected series IDs, remove it and remove the books from the selected books as well
		if (seriesIds.includes(series.id || '')) {
			setSeriesIds(seriesIds.filter((id) => id !== series.id));

			setSelectedBookIds(selectedBookIds.filter((id) => !booksFromSeries.includes(id)));
			// Otherwise, add the series ID to the selected series IDs and add the books from the series to the selected book IDs
		} else {
			setSeriesIds([...seriesIds, series.id || '']);

			setSelectedBookIds((prev) => [...prev, ...booksFromSeries.filter((id) => !prev.includes(id))]);
		}
	};

	/**
	 * Handler for clicking on a book in the list of books
	 *
	 * @param bookId The ID of the book that was clicked
	 * @param seriesId The ID of the series that the book belongs to
	 * @param stageId The ID of the stage that the series belongs to
	 */
	const handleClickBook = (bookId: string, seriesId: string, stageId: string) => {
		let newBookIds: string[] = [...selectedBookIds]; // Keep track of the new book IDs

		// If the series ID is in the selected series IDs, remove it and remove the books from that series from the selected book IDs
		if (seriesIds.includes(seriesId)) {
			setSeriesIds(seriesIds.filter((id) => id !== seriesId));

			// Get the books from the series
			const series = bookStages.find((s) => s.id === stageId)?.serieses?.find((s) => s.id === seriesId);
			const booksFromSeries: string[] = series?.books?.map((book) => book.id || '') || [];

			newBookIds = selectedBookIds.filter((id) => !booksFromSeries.includes(id));
		}

		// If the stage ID is in the selected stage IDs, remove it and remove the books from all the series in that stage from the selected book IDs
		if (stageIds.includes(stageId)) {
			setStageIds(stageIds.filter((id) => id !== stageId));

			const stage = bookStages.find((s) => s.id === stageId);
			const booksFromStageSeries: string[] =
				stage?.serieses?.flatMap((series) => series.books?.map((book) => book.id || '') || []) || [];

			newBookIds = selectedBookIds.filter((id) => !booksFromStageSeries.includes(id));
		}

		// If the book ID is already in the selected book IDs, remove it
		if (bookIds.includes(bookId)) {
			setBookIds(bookIds.filter((id) => id !== bookId));

			setSelectedBookIds(selectedBookIds.filter((id) => id !== bookId));
			// Otherwise, add the book ID to the selected book IDs
		} else {
			setBookIds([...bookIds, bookId]);

			setSelectedBookIds([...newBookIds, bookId]);
		}
	};

	const goToStage = (stageId: string) => {
		const element = document.getElementById(`stage-${stageId}`);

		if (element) {
			setTimeout(() => {
				element.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}, 0);
		}
	};

	const clearSelection = () => {
		setSelectedBookIds([]);
		setBookIds([]);
		setSeriesIds([]);
		setStageIds([]);
		setSearchText('');
	};

	const handleAssignBooks = async () => {
		const response = await apibridge.postStudentAdminBookAssign({
			studentProfileIds: selectedStudents,
			stageIds: stageIds,
			bookIds: selectedBookIds,
			seriesIds: seriesIds
		});
		if (response && response.data) {
			if (!response.data.isError) {
				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'success',
						heading: 'Book assignment',
						description: 'Books successfully assigned.'
					})
				);

				// Push to student list
				navigate(`/student-management/class/${classId}`);
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Assign book error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}
	};

	return (
		<div className="class-assign-books-page">
			<TitleSection
				backToText="Back to student list"
				backToUrl={`/student-management/class/${classId}`}
				title="Assign books"
				description="Use the checkboxes to select a Stage or expand the Stage and select individual books to assign."
			>
				<div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
					<Dropdown className="sort-dropdown">
						<Dropdown.Toggle variant="dropdown">Go to Stage</Dropdown.Toggle>
						<Dropdown.Menu>
							{dropdownStages.map((item, index) => (
								<Dropdown.Item key={index} onClick={() => goToStage(item.label)} as="button">
									Stage {item.label}
								</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown>

					<div className="d-flex align-items-center gap-3">
						{selectedBookIds.length > 0 && (
							<div className="selected-items-wrapper">
								<div className="d-flex flex-wrap row-gap-2 column-gap-4">
									<p className="mb-0">
										<strong>
											{selectedBookIds.length} Book{selectedBookIds.length === 1 ? '' : 's'} selected
										</strong>
									</p>
									<button
										onClick={() => {
											handleAssignBooks();
										}}
										className="btn btn-link text-start"
									>
										Assign book{selectedBookIds.length === 1 ? '' : 's'}
									</button>
								</div>
								<button
									onClick={() => {
										clearSelection();
									}}
									className="btn btn-reset reset-select"
								>
									<SvgMask path="/svg/cross-thin.svg" width={24} height={24} />
								</button>
							</div>
						)}
						<div className="search-input-wrapper">
							<input
								type="text"
								className="form-control search-input"
								placeholder="Filter by book title"
								onChange={(e) => {
									const target = e.target as HTMLInputElement;
									setSearchText(target.value);
								}}
							/>
						</div>
					</div>
				</div>
			</TitleSection>
			<section className="container">
				{loading ? (
					<div className="d-flex align-items-center justify-content-center">
						<DelayedFadeIn>
							<Spinner animation="border" role="status">
								<span className="visually-hidden">Loading...</span>
							</Spinner>
						</DelayedFadeIn>
					</div>
				) : (
					<Accordion className="book-stage-accordion gap-3-5 d-flex flex-column">
						{bookStages.map((stage, index) => {
							const stageName = convertToKebabCase(stage.name || '');

							return (
								<Accordion.Item
									key={stage.id}
									id={`stage-${stage.name}`}
									eventKey={stage.id + '-' + index}
									className={`stage-${stage.name}`}
								>
									<Accordion.Header>
										<div
											onClick={(e) => {
												e.stopPropagation();
												handleClickStage(stage);
											}}
											className={`stage-label btn-reset d-flex align-items-center gap-2-5 stage-${stage.name}`}
										>
											<input
												type="checkbox"
												name={`stage-${stage.name}`}
												id={`stage-${stageName}`}
												checked={stageIds.includes(stage.id || '')}
												onChange={() => handleClickStage(stage)}
											/>
											<label htmlFor={`stage-${stageName}`} className="h4 m-0">
												Stage {stage.name}
											</label>
										</div>
										{stage.attributes && (
											<div className="d-flex attributes">
												{stage.attributes.map((attribute, index) => {
													return (
														<div key={index} className="d-flex attribute-item">
															<p className="m-0 text-primary fw-medium">{attribute.name}</p>
															{attribute.children?.map((child, index) => {
																return (
																	<div key={index} className="attribute-btn text-primary">
																		{child}
																	</div>
																);
															})}
														</div>
													);
												})}
											</div>
										)}
									</Accordion.Header>
									<Accordion.Body className="py-3">
										{stage.serieses?.map((series) => {
											const seriesName = convertToKebabCase(series.name || '');
											const books = series?.books?.filter((book) =>
												book?.name?.toLowerCase().includes(searchText.toLowerCase() || '')
											);
											return (
												<div
													className="d-flex flex-column justify-content-center align-items-center py-2 gap-3-5"
													key={series.id}
													data-has-books={!!books?.length || false}
												>
													<div className="d-flex align-items-center gap-3-5">
														<input
															type="checkbox"
															name={series.name}
															id={`series-${seriesName}`}
															onChange={() => handleClickSeries(series, stage.id || '')}
															checked={seriesIds.includes(series.id || '') || stageIds.includes(stage.id || '')}
														/>
														<label htmlFor={`series-${seriesName}`} className="h3 m-0">
															<strong>{series.name}</strong>
														</label>
													</div>
													<div className="d-flex flex-wrap justify-content-center gap-4">
														{series.books
															?.filter((book) => book.name?.toLowerCase().includes(searchText.toLowerCase() || ''))
															.map((book) => {
																const bookName = convertToKebabCase(book.name || '');
																return (
																	<div className="d-flex flex-column align-items-center gap-2" key={book.id}>
																		<div className="position-relative">
																			<label htmlFor={`book-${bookName}`}>
																				<CroppedImage
																					src={book.coverUrl || 'https://via.placeholder.com/150'}
																					width={177}
																					height={219}
																					alt=""
																					className="rounded-3"
																				/>
																			</label>

																			<div className="checkbox-wrapper">
																				<input
																					className="book-checkbox"
																					type="checkbox"
																					name={book.name}
																					id={`book-${bookName}`}
																					checked={
																						bookIds.includes(book.id || '') ||
																						seriesIds.includes(series.id || '') ||
																						stageIds.includes(stage.id || '')
																					}
																					onChange={() =>
																						handleClickBook(book.id || '', series.id || '', stage.id || '')
																					}
																				/>
																			</div>
																		</div>
																		<label htmlFor={`book-${bookName}`} className="m-0">
																			<strong>{book.name}</strong>
																		</label>
																	</div>
																);
															})}
													</div>
												</div>
											);
										})}
									</Accordion.Body>
								</Accordion.Item>
							);
						})}
					</Accordion>
				)}
			</section>
		</div>
	);
};

export default StudentAssignBooks;
