import { FC, forwardRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastMessagesSlice } from '../../../store/slice/ToastMessages';

// PACKAGES
import { Accordion, Dropdown, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { Updater, useImmer } from 'use-immer';
import { useAppSelector } from '../../../store/slice';

// COMPONENTS
import TitleSection from '../../../components/TitleSection/TitleSection';
import SwitchToggle from '../../../components/_Helpers/SwitchToggle';

// UTILS
import { convertToKebabCase, guid } from '../../../libs/utils';
import SvgMask from '../../../components/_Helpers/SvgMask';
import CroppedImage from '../../../components/_Helpers/CroppedImage';
import DelayedFadeIn from '../../../components/_Helpers/DelayedFadeIn';

// TYPES
import apibridge from '../../../apibridge';
import { BookFavoriteCommand, BookListBook, BookListListResult } from '../../../api/models';
import { TooltipProps } from 'react-bootstrap';
import { UserType } from '../../../store/slice/User';

// am following this pattern: https://react-bootstrap.netlify.app/docs/components/overlays/#updating-position-dynamically
// otherwise the position doesn't shift correctly as the text updates
const UpdatingFavouriteTooltip = forwardRef<TooltipProps, any>(({ popper, children, show: _, ...props }, ref) => {
	useEffect(() => {
		popper?.scheduleUpdate?.();
	}, [children, popper]);

	return (
		<Tooltip ref={ref} {...props}>
			{children}
		</Tooltip>
	);
});

const StaffELibraryBook: FC<{
	bookListData: BookListListResult;
	stageId: string;
	seriesId: string;
	setBookListData: Updater<BookListListResult>;
	book: BookListBook;
	showOnlyFavorites: boolean;
	userCanFavorite: boolean;
}> = ({ book, stageId, seriesId, setBookListData, showOnlyFavorites, userCanFavorite }) => {
	const { coverUrl, id, isFavorite, name } = book;
	const dispatch = useDispatch();

	const [favoriteSubmitting, setFavoriteSubmitting] = useState(false);

	const postSetOrUnsetBookFavorite = async ({ bookId, action }: BookFavoriteCommand) => {
		setFavoriteSubmitting(true);

		const response = await apibridge.postBookFavorite({ bookId, action });
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				setBookListData((draft) => {
					// god bless Immer
					const draftStage = draft.stages?.find((stage) => stage.id === stageId);
					const draftSeries = draftStage?.serieses?.find((series) => series.id === seriesId);
					const draftBook = draftSeries?.books?.find((book) => book.id === bookId);
					if (draftBook && draftBook.isFavorite !== undefined) {
						draftBook.isFavorite = !isFavorite;
					}
				});
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'ELibrary error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setFavoriteSubmitting(false);
	};

	return !showOnlyFavorites || (showOnlyFavorites && isFavorite) ? (
		<div className="staff-book position-relative">
			<div className="d-flex flex-column align-items-center gap-2-5">
				<div className="position-relative">
					<CroppedImage
						src={coverUrl || 'https://via.placeholder.com/150'}
						width={177}
						height={219}
						alt=""
						className="book-image rounded-3"
					/>
					{userCanFavorite && (
						<OverlayTrigger
							placement="top"
							overlay={
								<UpdatingFavouriteTooltip className="tooltip-favorite">
									{isFavorite ? 'Remove Favourite' : 'Favourite'}
								</UpdatingFavouriteTooltip>
							}
						>
							<button
								type="button"
								className={`btn btn-favorite position-absolute end-0 bottom-0 z-2 align-items-end justify-content-end ${
									isFavorite ? 'is-favorited' : ''
								}`}
								disabled={favoriteSubmitting}
								onClick={() => postSetOrUnsetBookFavorite({ bookId: id, action: isFavorite ? 'Unset' : 'Set' })}
							>
								<SvgMask path="/svg/star.svg" width={24} height={24} />
							</button>
						</OverlayTrigger>
					)}
				</div>
				<Link to={`/elibrary/${id}`} className="stretched-link text-reset text-decoration-none">
					<strong>{name}</strong>
				</Link>
			</div>
		</div>
	) : null;
};

const StaffELibrary: FC = () => {
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState(true);
	const [bookListData, setBookListData] = useImmer<BookListListResult>({});
	const [filterText, setFilterText] = useState('');
	const [showOnlyFavorites, setShowOnlyFavorites] = useState(false);

	const user: UserType = useAppSelector((state) => state.user);
	const userCanFavorite = user.permissions?.includes('BookFavorite') || false;

	// creating a new obj for filtered book list data so we know in advance if a series has books or not
	const filteredBookListData: BookListListResult = {
		...bookListData,
		stages: bookListData.stages?.map((stage) => {
			return {
				...stage,
				serieses: stage.serieses?.map((series) => {
					return {
						...series,
						books: series.books?.filter((book) => {
							const filteredByTitle = book.name?.toLowerCase().includes(filterText.toLowerCase() || '');
							const filteredByFavourite = !showOnlyFavorites || (showOnlyFavorites && book.isFavorite);
							return filteredByTitle && filteredByFavourite;
						})
					};
				})
			};
		})
	};

	const dropdownStages =
		bookListData?.stages?.map((stage) => {
			const stageId = convertToKebabCase(stage.name || '');
			return {
				domId: `#stage-${stageId}`,
				name: stage.name || ''
			};
		}) || [];

	const getBookListData = async () => {
		const response = await apibridge.getBookList();

		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				setBookListData(response.data.result);
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Routines error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setIsLoading(false);
	};

	const goToStage = (stageId: string) => {
		const element = document.querySelector(stageId);
		if (element)
			setTimeout(() => {
				element.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}, 0);
	};

	useEffect(() => {
		getBookListData();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="page-staff-library d-flex flex-column flex-grow-1">
			<TitleSection title="eLibrary" description="View and read books across all stages.">
				<div className="row row-gap-3">
					<div className="col">
						{user.permissions?.includes('BookStages') && (
							<Dropdown className="sort-dropdown">
								<Dropdown.Toggle variant="dropdown" disabled={filterText !== ''}>
									Go to Stage
								</Dropdown.Toggle>
								<Dropdown.Menu>
									{dropdownStages.map((item, index) => (
										<Dropdown.Item onClick={() => goToStage(item.domId)} key={index} as="button">
											Stage {item.name}
										</Dropdown.Item>
									))}
								</Dropdown.Menu>
							</Dropdown>
						)}
					</div>
					{userCanFavorite && (
						<div className="col-auto">
							<div className="d-flex align-items-center gap-2-5 h-100">
								<label htmlFor="input-favorites" className="d-flex align-items-center gap-2-5">
									<SvgMask path={'/svg/star-filled.svg'} width={24} height={24} />
									<strong>Favourites</strong>
								</label>

								<SwitchToggle
									id="input-favorites"
									checked={showOnlyFavorites}
									onChange={() => setShowOnlyFavorites(!showOnlyFavorites)}
								/>
							</div>
						</div>
					)}
					<div className="col-auto col-md-4 col-lg-3">
						<div className="position-relative d-flex align-items-center">
							<input
								type="text"
								className={`form-control field-filter ${filterText ? 'pe-5' : ''}`}
								placeholder="Filter by book title"
								value={filterText}
								onChange={(e) => {
									const target = e.target as HTMLInputElement;
									setFilterText(target.value);
								}}
							/>
							{filterText && (
								<button type="button" className="btn btn-input-clear" onClick={() => setFilterText('')}>
									<span className="visually-hidden">Clear</span>
									<i></i>
								</button>
							)}
						</div>
					</div>
				</div>
			</TitleSection>

			<section className="container h-100">
				{isLoading ? (
					<div className="d-flex justify-content-center">
						<DelayedFadeIn>
							<Spinner animation="border" role="status">
								<span className="visually-hidden">Loading...</span>
							</Spinner>
						</DelayedFadeIn>
					</div>
				) : user.permissions?.includes('BookStages') ? (
					<Accordion className="book-stage-accordion gap-3-5 d-flex flex-column" alwaysOpen>
						{filteredBookListData?.stages?.map((stage) => {
							const stageName = convertToKebabCase(stage.name || '');
							const stageHasFilteredBooks = stage.serieses?.some((series) => series.books?.length);

							return (
								<Accordion.Item
									key={stage.id}
									id={`stage-${stageName}`}
									eventKey={`${stageName}-${stage.id}`}
									className={`stage-${stage.name}`}
								>
									<Accordion.Header>
										<div className={`stage-label btn-reset d-flex align-items-center gap-2-5 stage-${stage.name}`}>
											<h4 className="m-0">Stage {stage.name}</h4>
										</div>
										{stage.attributes && (
											<div className="attributes d-flex">
												{stage.attributes.map((attribute, attributeIndex) => {
													return (
														<div key={attributeIndex} className="attribute-item d-flex">
															<p className="m-0 text-primary fw-medium">{attribute.name}</p>
															{attribute.children?.map((attributeChild, attributeChildIndex) => {
																return (
																	<div key={attributeChildIndex} className="attribute-btn text-primary">
																		{attributeChild}
																	</div>
																);
															})}
														</div>
													);
												})}
											</div>
										)}
									</Accordion.Header>
									<Accordion.Body className="d-flex flex-column gap-4 py-3">
										{stage.serieses?.map((series) => {
											const { books = [] } = series;

											return (
												<div
													key={series.id}
													className="d-flex flex-column justify-content-center align-items-center py-2 gap-3-5"
													data-has-books={stageHasFilteredBooks}
												>
													{books.length > 0 && (
														<>
															<div className="d-flex align-items-center gap-3-5">
																<h3 className="m-0">
																	<strong>{series.name}</strong>
																</h3>
															</div>
															<div className="d-flex flex-wrap justify-content-center gap-4">
																{books.map((book) => (
																	<StaffELibraryBook
																		key={book.id}
																		seriesId={series.id || ''}
																		stageId={stage.id || ''}
																		bookListData={bookListData}
																		setBookListData={setBookListData}
																		book={book}
																		showOnlyFavorites={showOnlyFavorites}
																		userCanFavorite={userCanFavorite}
																	/>
																))}
															</div>
														</>
													)}
												</div>
											);
										})}
									</Accordion.Body>
								</Accordion.Item>
							);
						})}
					</Accordion>
				) : (
					<div className="d-flex align-items-center justify-content-center h-100">
						<h3 className="text-center text-shades-500 m-0">You don't have permission to view this content.</h3>
					</div>
				)}
			</section>
		</div>
	);
};

export default StaffELibrary;
