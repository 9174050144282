import React, { FC, useRef } from 'react';

// PACKAGES
import { Field, FieldArrayRenderProps, FieldProps, FormikProps } from 'formik';

// COMPONENTS
import SvgMask from '../_Helpers/SvgMask';

// TYPES
import { StaffInviteEmail, StaffUploadEmail } from '../../api/models';
import { InviteTeachersFormValuesType } from '../../pages/TeacherAdmin/InviteTeachers';

interface ChipTypes {
	email: StaffInviteEmail;
	index: number;
	formik: FormikProps<InviteTeachersFormValuesType>;
	arrayHelpers: FieldArrayRenderProps;
	remainingInvites: number | undefined;
	validEmails?: StaffInviteEmail[];
}

const Chip: FC<ChipTypes> = ({ email, index, arrayHelpers, remainingInvites, formik, validEmails }) => {
	const contentEditableRef = useRef<HTMLDivElement>(null);
	const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

	const setExcess = () => {
		const indexValidEmail = validEmails?.findIndex(
			(item: StaffInviteEmail) => item.emailAddress === email.emailAddress
		);

		if (!remainingInvites || !indexValidEmail || indexValidEmail === -1) return;

		if (indexValidEmail > remainingInvites - 1) {
			return true;
		}
	};

	const handleChange = (event: React.FocusEvent<HTMLDivElement>) => {
		const email = event.target.textContent;

		if (email === '') {
			arrayHelpers.remove(index);
		} else {
			arrayHelpers.replace(index, {
				emailAddress: email,
				status: !emailRegex.test(email || '') ? 'Invalid' : 'Valid'
			});
		}
	};

	const handleRemove = () => {
		arrayHelpers.remove(index);
	};

	return (
		<Field key={email.emailAddress} name={`emails.${index}.emailAddress`}>
			{({ field, form, meta }: FieldProps<string>) => {
				const emailErrors = formik.errors.emails as StaffUploadEmail[] | undefined;

				return (
					<div
						className={`badge chip ${
							emailErrors?.some((item) => item.emailAddress === email.emailAddress) || setExcess() ? 'error' : ''
						} `}
					>
						{(emailErrors?.some((item) => item.emailAddress === email.emailAddress) || setExcess()) && (
							<i className="error-icon"></i>
						)}
						<div
							suppressContentEditableWarning
							contentEditable={true}
							className="email"
							onBlur={handleChange}
							dangerouslySetInnerHTML={{ __html: field.value as string }}
							ref={contentEditableRef}
							onPaste={(e) => e.preventDefault()}
						/>

						<button type="button" className="btn btn-reset close" aria-label="Close" onClick={() => handleRemove()}>
							<SvgMask path="/svg/cross.svg" width={16} height={16} />
						</button>
					</div>
				);
			}}
		</Field>
	);
};

export default Chip;
