import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// REDUX
import { useAppDispatch, useAppSelector } from '../../../store/slice';
import { UserSlice, UserType } from '../../../store/slice/User';

// COMPONENTS
import DelayedFadeIn from '../../../components/_Helpers/DelayedFadeIn';
import { Spinner } from 'react-bootstrap';

// API
import apibridge from '../../../apibridge';

// UTILS
import { ToastMessagesSlice } from '../../../store/slice/ToastMessages';
import { guid } from '../../../libs/utils';

const StudentLoginQR: React.FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [searchParams] = useSearchParams();

	const token = searchParams.get('token') || '';

	const [isSubmitting, setIsSubmitting] = useState(false);

	const user: UserType = useAppSelector((state) => state.user);

	const isLoggedIn = user?.loggedIn || false;

	const validateToken = async () => {
		setIsSubmitting(true);

		// Need to log out the user before checking the token to avoid QR codes read the user as logged in and will not do anything.
		if (isLoggedIn) {
			dispatch(UserSlice.actions.logOut());
		}

		const response = await apibridge.postAuthenticateToken({
			token
		});

		if (response.data) {
			if (response.data.isError) {
				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'danger',
						heading: 'Login error',
						description: 'Token invalid'
					})
				);

				navigate('/login');
			} else if (response.data.result) {
				// the login was successful!
				const { refreshToken = '', token = '' } = response.data.result;
				localStorage.setItem('refreshToken', refreshToken);
				localStorage.setItem('token', token);

				// upon login, trigger the [user] useEffect in App.tsx to get the user details
				dispatch(
					UserSlice.actions.update({
						loggedIn: true
					})
				);

				navigate('/elibrary');
			}
		}

		setIsSubmitting(false);
	};

	useEffect(() => {
		validateToken();
	}, []);

	return (
		<>
			{isSubmitting ? (
				<div className="d-flex align-items-center justify-content-center position-absolute w-100 h-100">
					<DelayedFadeIn>
						<Spinner animation="border" role="status">
							<span className="visually-hidden">Loading...</span>
						</Spinner>
					</DelayedFadeIn>
				</div>
			) : (
				<>
					<picture>
						<source srcSet="./img/login-bg.webp" />
						<img
							src="./img/login-bg.jpg"
							className="position-fixed w-100 h-100 object-fit object-fit-cover user-select-none"
							alt="Tiled books background"
						/>
					</picture>
					<main className="page-student-login d-flex flex-column align-items-center flex-grow-1"></main>
				</>
			)}
		</>
	);
};

export default StudentLoginQR;
