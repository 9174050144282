import { FC, HTMLAttributeAnchorTarget, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/slice';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';

// PACKAGES
import { Dropdown, Offcanvas, Spinner } from 'react-bootstrap';

// COMPONENTS
import TitleSection from '../../components/TitleSection/TitleSection';

// UTILS
import SvgMask from '../../components/_Helpers/SvgMask';
import { guid } from '../../libs/utils';
import DelayedFadeIn from '../../components/_Helpers/DelayedFadeIn';

// TYPES
import apibridge from '../../apibridge';
import { PlannerWeekActivity, PlannerWeekDay, PlannerWeekWeekResult } from '../../api/models';
import { UserType } from '../../store/slice/User';

export const ConditionalLinkWrapper: FC<{
	children: any;
	link?: string | null;
	reloadDocument?: boolean;
	target?: HTMLAttributeAnchorTarget;
	className?: string;
}> = ({ children, link, reloadDocument = false, target = '', className = '' }) => {
	return link ? (
		<Link to={link} reloadDocument={reloadDocument} target={target} className={className}>
			{children}
		</Link>
	) : (
		<div className={className}>{children}</div>
	);
};

const PlannerItem: FC<{ activity: PlannerWeekActivity }> = ({ activity }) => {
	const isRoutine = activity.type === 'Routine';
	const isResource = activity.type === 'Resource';

	return (
		<ConditionalLinkWrapper
			link={isRoutine ? `/plans/routines/${activity.routine?.id}` : isResource ? activity.resource?.resourceUrl : null}
			target={isResource ? '_blank' : '_self'}
			className={`activity ${activity.type} d-block`}
		>
			<div className="activity-box fw-semibold body-tiny d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-center justify-content-lg-between gap-1">
				<div>{activity.name}</div>
				{activity.type === 'Routine' ? (
					<div className="activity-type-icon">
						<SvgMask path="/svg/Routines-Class.svg" width={16} height={16} />
					</div>
				) : activity.type === 'Resource' ? (
					<div className="activity-type-icon">
						<SvgMask path="/svg/Resources-Stack.svg" width={16} height={16} />
					</div>
				) : null}
			</div>
		</ConditionalLinkWrapper>
	);
};

const Footnote: FC = () => {
	return (
		<div className="d-flex gap-2-5 p-4 footnotes mt-2">
			<SvgMask path="/svg/plans-clipboard.svg" width={24} height={24} />
			<p className="mb-0">
				Stage 7.1 Additional Teacher Resource Unit is available for schools following the{' '}
				<a
					href="https://www.dropbox.com/scl/fi/hl4qnubcuygqupcjijk0k/TAR_S7U1_NSW_final.pdf?rlkey=zoko1ceceye67exwxoq5vuwea&dl=0"
					target="_blank"
					rel="noreferrer"
				>
					NSW curriculum
				</a>{' '}
				(split vowel digraphs).
			</p>
		</div>
	);
};

const Plans: FC = () => {
	const dispatch = useAppDispatch();

	const [loading, setLoading] = useState(true);
	const [loadingPrevNextWeek, setLoadingPrevNextWeek] = useState<'prev' | 'next' | ''>('');

	const [plannerDetails, setPlannerDetails] = useState<PlannerWeekWeekResult>();
	const [showTeachingSequenceModal, setShowTeachingSequenceModal] = useState(false);
	const [activeTab, setActiveTab] = useState<'foundation' | 'Year 1' | 'Year 2'>('foundation');
	const [isScrolled, setIsScrolled] = useState(false);

	const user: UserType = useAppSelector((state) => state.user);

	// since the order of the rendering is now important due to how the 'Play Lesson' functionality works, am presetting an order here
	const planOrder: Array<keyof PlannerWeekDay> = [
		'warmUpActivities',
		'reviseActivities',
		'teachActivities',
		'practiseTeacherLedActivities',
		'practiseIndependentActivities',
		'applyTeacherLedActivities',
		'applyIndependentActivities',
		'reviewActivities'
	];
	const [
		warmUpActivities,
		reviseActivities,
		teachActivities,
		practiseTeacherLedActivities,
		practiseIndependentActivities,
		applyTeacherLedActivities,
		applyIndependentActivities,
		reviewActivities
	] = planOrder;

	const getPlannerWeek = async (id?: string) => {
		const response = await apibridge.getPlannerWeek(id || '');
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				setPlannerDetails(response.data.result);
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Planner error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setLoading(false);
		setLoadingPrevNextWeek('');
	};

	const hasActivitiesInGroup = (activity: string) => {
		const days = plannerDetails?.days || [];

		return days.filter((day) => day[activity as keyof typeof day]).length > 0;
	};

	const onClickWeek = async (year: string, week: string, stage: string) => {
		if (!user.permissions?.includes('PlannerWeekFind')) {
			dispatch(
				ToastMessagesSlice.actions.add({
					id: guid(),
					type: 'info',
					heading: 'Plan unavailable',
					description: "You don't have permission for this action."
				})
			);
			return;
		}

		const response = await apibridge.getPlannerWeekFind(year, week, stage);

		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				await getPlannerWeek(response.data.result.id);
				setShowTeachingSequenceModal(false);
			} else {
				dispatch(
					ToastMessagesSlice.actions.add({
						id: guid(),
						type: 'info',
						heading: 'Plan unavailable',
						description: 'The plan for this week is unavailable.'
					})
				);

				for (const err of response.data?.validationErrors || []) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Plans error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}
	};

	useEffect(() => {
		getPlannerWeek();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="page-plans">
			<TitleSection title="Plans">
				<div className="row row-gap-3">
					<div className="col">
						<div className="d-flex align-items-center gap-3 h-100">
							{user.permissions?.includes('PlannerWeekFind') && (
								<div className="d-flex gap-2-5">
									<button
										type="button"
										aria-label="Previous Week"
										onClick={() => {
											setLoadingPrevNextWeek('prev');
											getPlannerWeek(plannerDetails?.previousId || '');
										}}
										className="btn btn-icon p-0 rotate-180"
										disabled={plannerDetails?.previousId === undefined || loading || loadingPrevNextWeek !== ''}
									>
										{loadingPrevNextWeek === 'prev' ? (
											<Spinner animation="border" size="sm" />
										) : (
											<SvgMask path={'/svg/chevron-right.svg'} width={24} height={24} />
										)}
									</button>
									<button
										type="button"
										aria-label="Next Week"
										onClick={() => {
											setLoadingPrevNextWeek('next');
											getPlannerWeek(plannerDetails?.nextId || '');
										}}
										className="btn btn-icon p-0"
										disabled={plannerDetails?.nextId === undefined || loading || loadingPrevNextWeek !== ''}
									>
										{loadingPrevNextWeek === 'next' ? (
											<Spinner animation="border" size="sm" />
										) : (
											<SvgMask path={'/svg/chevron-right.svg'} width={24} height={24} />
										)}
									</button>
								</div>
							)}
							<h4 className="mb-0">
								<strong>{plannerDetails?.yearName}</strong>
							</h4>
							<span className="dot">•</span>
							<h4 className="mb-0">
								{plannerDetails?.stageName && (
									<strong>
										{plannerDetails.stageName.toLowerCase().includes("revision") ? plannerDetails.stageName : `Stage ${plannerDetails.stageName}`}
									</strong>
								)}
							</h4>
							<span className="dot text-shades-500">•</span>
							<h4 className="text-shades-500 mb-0">
								<strong>{plannerDetails?.name}</strong>
							</h4>
						</div>
					</div>
					<div className="col-12 col-lg-6">
						<div className="d-flex justify-content-lg-end gap-2">
							<button onClick={() => setShowTeachingSequenceModal(true)} className="btn btn-white">
								<SvgMask path="/svg/calendar.svg" width={16} height={16} />
								Teaching Sequence
							</button>
							{(plannerDetails?.planUrl || plannerDetails?.resourcesUrl) && (
								<Dropdown>
									<Dropdown.Toggle as="button" variant="white" id="download-week" className="btn btn-dropdown">
										<SvgMask path="/svg/download.svg" width={16} height={16} />
										Download Week
									</Dropdown.Toggle>
									<Dropdown.Menu className="w-100">
										{plannerDetails?.planUrl && (
											<Dropdown.Item href={plannerDetails.planUrl} download>
												This Week's Plan
											</Dropdown.Item>
										)}
										{plannerDetails?.resourcesUrl && (
											<Dropdown.Item href={plannerDetails.resourcesUrl} download>
												This Week's Resources
											</Dropdown.Item>
										)}
									</Dropdown.Menu>
								</Dropdown>
							)}
						</div>
					</div>
				</div>
			</TitleSection>
			<div className="container">
				{loading ? (
					<div className="d-flex align-items-center justify-content-center">
						<DelayedFadeIn>
							<Spinner animation="border" role="status">
								<span className="visually-hidden">Loading...</span>
							</Spinner>
						</DelayedFadeIn>
					</div>
				) : (
					<>
						<div className={`d-flex flex-column planner-wrapper stage-${plannerDetails?.stageName}`}>
							<div className="weekly-planner d-flex align-items-stretch justify-content-between">
								{Array.from({ length: 5 }, (_, i) => i + 1).map((day) => {
									const activeDay = plannerDetails?.days?.find((dayDetils) => dayDetils.name === 'Day ' + day);

									let firstActivity: PlannerWeekActivity | undefined;
									for (const plan of planOrder) {
										const activePlan = activeDay?.[plan] as Array<PlannerWeekActivity>;
										const firstRoutine = activePlan?.find((plan) => plan.type === 'Routine');
										if (firstRoutine) {
											firstActivity = firstRoutine;
											break;
										}
									}

									return (
										<div key={day} className="day-title-wrapper">
											<div className="p-1">
												<div className="day-title px-3 py-2 d-flex align-items-center justify-content-between gap-2">
													<span className="flex-shrink-0">
														<strong>Day {day}</strong>
													</span>
													{firstActivity?.routine?.id && (
														<Link
															to={`/plans/routines/${firstActivity.routine.id}/1?dayId=${activeDay?.id}`}
															className="btn-play-lesson btn btn-sm rounded-2 text-start"
														>
															<span className="label">Play lesson</span>
															<SvgMask path="/svg/play.svg" width={18} height={18} />
														</Link>
													)}
												</div>
											</div>
										</div>
									);
								})}
							</div>
							<div className="details-wrapper d-flex flex-column">
								<div className="d-flex">
									{plannerDetails?.days?.some((day) => day.description) &&
										Array.from({ length: 5 }, (_, i) => i + 1).map((day, dayIndex) => {
											const activeDay =
												plannerDetails?.days?.find((dayDetils) => dayDetils.name === 'Day ' + day) || {};

											return (
												<div
													key={activeDay.id || dayIndex}
													className="description activity-column text-shades-500 fw-semibold body-small"
												>
													{activeDay.description ? activeDay.description : ''}
												</div>
											);
										})}
								</div>

								{hasActivitiesInGroup(warmUpActivities) && (
									<div className="d-flex">
										{Array.from({ length: 5 }, (_, i) => i + 1).map((day, dayIndex) => {
											const activeDay =
												plannerDetails?.days?.find((dayDetils) => dayDetils.name === 'Day ' + day) || {};

											return (
												<div key={`${activeDay.id}-${dayIndex}`} className="activity-column d-flex flex-column gap-2-5">
													<div className="time-title text-shades-800 body-small">
														<strong>{day === 1 ? 'Warm Up' : ''}</strong>
														{day === 1 && plannerDetails?.warmUpTime && (
															<span className="activity-time text-shades-500 fw-semibold body-tiny text-nowrap">
																<span className="me-1">•</span> {plannerDetails.warmUpTime}
															</span>
														)}
													</div>

													<div className="d-flex flex-column gap-1">
														{activeDay.warmUpActivities?.map((activity) => (
															<PlannerItem key={activity.id} activity={activity} />
														))}
													</div>
													<div className="single-divider"></div>
												</div>
											);
										})}
									</div>
								)}
								{hasActivitiesInGroup(reviseActivities) && (
									<div className="d-flex">
										{Array.from({ length: 5 }, (_, i) => i + 1).map((day, dayIndex) => {
											const activeDay =
												plannerDetails?.days?.find((dayDetils) => dayDetils.name === 'Day ' + day) || {};

											return (
												<div key={`${activeDay.id}-${dayIndex}`} className="activity-column d-flex flex-column gap-2-5">
													<div className="time-title text-shades-800 body-small">
														<strong>{day === 1 ? 'Revise' : ''}</strong>
														{day === 1 && plannerDetails?.reviseTime && (
															<span className="activity-time text-shades-500 fw-semibold body-tiny text-nowrap">
																<span className="me-1">•</span> {plannerDetails.reviseTime}
															</span>
														)}
													</div>

													<div className="d-flex flex-column gap-1">
														{activeDay.reviseActivities?.map((activity) => (
															<PlannerItem key={activity.id} activity={activity} />
														))}
													</div>
													<div className="single-divider"></div>
												</div>
											);
										})}
									</div>
								)}
								{hasActivitiesInGroup(teachActivities) && (
									<div className="d-flex">
										{Array.from({ length: 5 }, (_, i) => i + 1).map((day, dayIndex) => {
											const activeDay =
												plannerDetails?.days?.find((dayDetils) => dayDetils.name === 'Day ' + day) || {};

											return (
												<div key={`${activeDay.id}-${dayIndex}`} className="activity-column d-flex flex-column gap-2-5">
													<div className="time-title text-shades-800 body-small">
														<strong>{day === 1 ? 'Teach' : ''}</strong>
														{day === 1 && plannerDetails?.teachTime && (
															<span className="activity-time text-shades-500 fw-semibold body-tiny text-nowrap">
																<span className="me-1">•</span> {plannerDetails.teachTime}
															</span>
														)}
													</div>

													<div className="d-flex flex-column gap-1">
														{activeDay.teachActivities?.map((activity) => (
															<PlannerItem key={activity.id} activity={activity} />
														))}
													</div>
													<div className="single-divider"></div>
												</div>
											);
										})}
									</div>
								)}
								{hasActivitiesInGroup(practiseTeacherLedActivities) && (
									<div className="d-flex">
										{Array.from({ length: 5 }, (_, i) => i + 1).map((day, dayIndex) => {
											const activeDay =
												plannerDetails?.days?.find((dayDetils) => dayDetils.name === 'Day ' + day) || {};

											return (
												<div key={`${activeDay.id}-${dayIndex}`} className="activity-column d-flex flex-column gap-2-5">
													<div className="time-title text-shades-800 body-small">
														<strong>{day === 1 ? 'Practise (Teacher-led)' : ''}</strong>
														{day === 1 && plannerDetails?.practiseTeacherLedTime && (
															<span className="activity-time text-shades-500 fw-semibold body-tiny text-nowrap">
																<span className="me-1">•</span> {plannerDetails.practiseTeacherLedTime}
															</span>
														)}
													</div>

													<div className="d-flex flex-column gap-1">
														{activeDay.practiseTeacherLedActivities?.map((activity) => (
															<PlannerItem key={activity.id} activity={activity} />
														))}
													</div>
													<div className="single-divider"></div>
												</div>
											);
										})}
									</div>
								)}
								{hasActivitiesInGroup(practiseIndependentActivities) && (
									<div className="d-flex">
										{Array.from({ length: 5 }, (_, i) => i + 1).map((day, dayIndex) => {
											const activeDay =
												plannerDetails?.days?.find((dayDetils) => dayDetils.name === 'Day ' + day) || {};

											return (
												<div key={`${activeDay.id}-${dayIndex}`} className="activity-column d-flex flex-column gap-2-5">
													<div className="time-title text-shades-800 body-small">
														<strong>{day === 1 ? 'Practise (Independent)' : ''}</strong>
														{day === 1 && plannerDetails?.practiseIndependentTime && (
															<span className="activity-time text-shades-500 fw-semibold body-tiny text-nowrap">
																<span className="me-1">•</span> {plannerDetails.practiseIndependentTime}
															</span>
														)}
													</div>

													<div className="d-flex flex-column gap-1">
														{activeDay.practiseIndependentActivities?.map((activity) => (
															<PlannerItem key={activity.id} activity={activity} />
														))}
													</div>
													<div className="single-divider"></div>
												</div>
											);
										})}
									</div>
								)}
								{hasActivitiesInGroup(applyTeacherLedActivities) && (
									<div className="d-flex">
										{Array.from({ length: 5 }, (_, i) => i + 1).map((day, dayIndex) => {
											const activeDay =
												plannerDetails?.days?.find((dayDetils) => dayDetils.name === 'Day ' + day) || {};

											return (
												<div key={`${activeDay.id}-${dayIndex}`} className="activity-column d-flex flex-column gap-2-5">
													<div className="time-title text-shades-800 body-small">
														<strong>{day === 1 ? 'Apply (Teacher-led)' : ''}</strong>
														{day === 1 && plannerDetails?.applyTeacherLedTime && (
															<span className="activity-time text-shades-500 fw-semibold body-tiny text-nowrap">
																<span className="me-1">•</span> {plannerDetails.applyTeacherLedTime}
															</span>
														)}
													</div>

													<div className="d-flex flex-column gap-1">
														{activeDay.applyTeacherLedActivities?.map((activity) => (
															<PlannerItem key={activity.id} activity={activity} />
														))}
													</div>
													<div className="single-divider"></div>
												</div>
											);
										})}
									</div>
								)}
								{hasActivitiesInGroup(applyIndependentActivities) && (
									<div className="d-flex">
										{Array.from({ length: 5 }, (_, i) => i + 1).map((day, dayIndex) => {
											const activeDay =
												plannerDetails?.days?.find((dayDetils) => dayDetils.name === 'Day ' + day) || {};

											return (
												<div key={`${activeDay.id}-${dayIndex}`} className="activity-column d-flex flex-column gap-2-5">
													<div className="time-title text-shades-800 body-small">
														<strong>{day === 1 ? 'Apply (Independent)' : ''}</strong>
														{day === 1 && plannerDetails?.applyIndependentTime && (
															<span className="activity-time text-shades-500 fw-semibold body-tiny text-nowrap">
																<span className="me-1">•</span> {plannerDetails.applyIndependentTime}
															</span>
														)}
													</div>

													<div className="d-flex flex-column gap-1">
														{activeDay.applyIndependentActivities?.map((activity) => (
															<PlannerItem key={activity.id} activity={activity} />
														))}
													</div>
													<div className="single-divider"></div>
												</div>
											);
										})}
									</div>
								)}
								{hasActivitiesInGroup(reviewActivities) && (
									<div className="d-flex">
										{Array.from({ length: 5 }, (_, i) => i + 1).map((day, dayIndex) => {
											const activeDay =
												plannerDetails?.days?.find((dayDetils) => dayDetils.name === 'Day ' + day) || {};

											return (
												<div key={`${activeDay.id}-${dayIndex}`} className="activity-column d-flex flex-column gap-2-5">
													<div className="time-title text-shades-800 body-small">
														<strong>{day === 1 ? 'Review' : ''}</strong>
														{day === 1 && plannerDetails?.reviewTime && (
															<span className="activity-time text-shades-500 fw-semibold body-tiny text-nowrap">
																<span className="me-1">•</span> {plannerDetails.reviewTime}
															</span>
														)}
													</div>

													<div className="d-flex flex-column gap-1">
														{activeDay.reviewActivities?.map((activity) => (
															<PlannerItem key={activity.id} activity={activity} />
														))}
													</div>
													<div className="single-divider"></div>
												</div>
											);
										})}
									</div>
								)}
							</div>
						</div>
						{plannerDetails?.footNote && (
							<div className="d-flex gap-2-5 p-4 footnotes">
								<SvgMask path="/svg/plans-clipboard.svg" width={24} height={24} />
								<div>
									<div
										className="wysiwyg-content"
										dangerouslySetInnerHTML={{
											__html: plannerDetails.footNote
										}}
									></div>
								</div>
							</div>
						)}
					</>
				)}
			</div>

			<Offcanvas
				show={showTeachingSequenceModal}
				onHide={() => setShowTeachingSequenceModal(false)}
				placement="end"
				bsPrefix="teaching-sequence offcanvas"
			>
				<Offcanvas.Header
					closeButton
					className="position-relative d-flex flex-column align-items-center justify-content-center"
				>
					<div className={`scroll-gradient ${isScrolled ? 'scrolled' : ''}`}></div>
					<Offcanvas.Title className="text-shades-800 d-flex flex-column align-items-center">
						<h2>
							<strong>Teaching Sequence</strong>
						</h2>
						<p className="mb-0">Click on a teaching week to go to the weekly plan.</p>
					</Offcanvas.Title>
					<div className="tab-wrapper gap-1">
						<button
							onClick={() => setActiveTab('foundation')}
							className={`btn tab-label tab ${activeTab === 'foundation' ? 'active' : ''}`}
						>
							<h4 className="mb-0">
								<strong>Foundation</strong>
							</h4>
						</button>
						<button
							onClick={() => setActiveTab('Year 1')}
							className={`btn tab-label tab ${activeTab === 'Year 1' ? 'active' : ''}`}
						>
							<h4 className="mb-0">
								<strong>Year 1</strong>
							</h4>
						</button>
						<button
							onClick={() => setActiveTab('Year 2')}
							className={`btn tab-label tab ${activeTab === 'Year 2' ? 'active' : ''}`}
						>
							<h4 className="mb-0">
								<strong>Year 2</strong>
							</h4>
						</button>
					</div>
				</Offcanvas.Header>

				<Offcanvas.Body
					className="bg-white"
					onScroll={(e) => {
						const target = e.target as HTMLDivElement;
						const offset = target.scrollTop;
						setIsScrolled(offset > 0);
					}}
				>
					{activeTab === 'foundation' ? (
						<>
							<div className="sequence-wrapper">
								<div className="stage-1">
									<strong>Stage 1</strong>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '1', '1')}
									className="btn s1-w1 fw-semibold body-small"
								>
									Week 1
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '1', '2')}
									className="btn s1-w2 fw-semibold body-small"
								>
									Week 2
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '1', '3')}
									className="btn s1-w3 fw-semibold body-small"
								>
									Week 3
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '1', '4')}
									className="btn s1-w4 fw-semibold body-small"
								>
									Week 4
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '1', '5')}
									className="btn s1-w5 fw-semibold body-small"
								>
									Week 5
								</button>
								<div className="s1-w1-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">m</p>
										</div>
										<div className="attribute">
											<p className="m-0">s</p>
										</div>
									</div>
								</div>
								<div className="s1-w2-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">f</p>
										</div>
										<div className="attribute">
											<p className="m-0">a</p>
										</div>
									</div>
								</div>
								<div className="s1-w3-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">p</p>
										</div>
										<div className="attribute">
											<p className="m-0">t</p>
										</div>
									</div>
								</div>
								<div className="s1-w4-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">c</p>
										</div>
										<div className="attribute">
											<p className="m-0">i</p>
										</div>
									</div>
								</div>
								<div className="s1-w5-a">
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">
											revise,
											<br />
											practise,
											<br />
											apply
										</p>
									</div>
								</div>

								<div className="stage-2">
									<strong>Stage 2</strong>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '2', '1')}
									className="btn s2-w1 fw-semibold body-small"
								>
									Week 1
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '2', '2')}
									className="btn s2-w2 fw-semibold body-small"
								>
									Week 2
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '2', '3')}
									className="btn s2-w3 fw-semibold body-small"
								>
									Week 3
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '2', '4')}
									className="btn s2-w4 fw-semibold body-small"
								>
									Week 4
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '2', '5')}
									className="btn s2-w5 fw-semibold body-small"
								>
									Week 5
								</button>
								<div className="s2-w1-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">b</p>
										</div>
										<div className="attribute">
											<p className="m-0">h</p>
										</div>
									</div>
								</div>
								<div className="s2-w2-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">n</p>
										</div>
										<div className="attribute">
											<p className="m-0">o</p>
										</div>
									</div>
								</div>
								<div className="s2-w3-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">d</p>
										</div>
										<div className="attribute">
											<p className="m-0">g</p>
										</div>
									</div>
								</div>
								<div className="s2-w4-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">l</p>
										</div>
										<div className="attribute">
											<p className="m-0">v</p>
										</div>
									</div>
								</div>
								<div className="s2-w5-a">
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">
											revise,
											<br />
											practise,
											<br />
											apply
										</p>
									</div>
								</div>

								<div className="stage-3">
									<strong>Stage 3</strong>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '3', '1')}
									className="btn s3-w1 fw-semibold body-small"
								>
									Week 1
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '3', '2')}
									className="btn s3-w2 fw-semibold body-small"
								>
									Week 2
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '3', '3')}
									className="btn s3-w3 fw-semibold body-small"
								>
									Week 3
								</button>
								<div className="s3-w1-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">y</p>
										</div>
										<div className="attribute">
											<p className="m-0">r</p>
										</div>
									</div>
								</div>
								<div className="s3-w2-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">e</p>
										</div>
									</div>
								</div>
								<div className="s3-w3-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">qu</p>
										</div>
										<div className="attribute">
											<p className="m-0">z</p>
										</div>
									</div>
								</div>

								<div className="stage-4">
									<strong>Stage 4</strong>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '4', '1')}
									className="btn s4-w1 fw-semibold body-small"
								>
									Week 1
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '4', '2')}
									className="btn s4-w2 fw-semibold body-small"
								>
									Week 2
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '4', '3')}
									className="btn s4-w3 fw-semibold body-small"
								>
									Week 3
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '4', '4')}
									className="btn s4-w4 fw-semibold body-small"
								>
									Week 4
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '4', '5')}
									className="btn s4-w5 fw-semibold body-small"
								>
									Week 5
								</button>
								<div className="s4-w1-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">j</p>
										</div>
										<div className="attribute">
											<p className="m-0">u</p>
										</div>
									</div>
								</div>
								<div className="s4-w2-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">k</p>
										</div>
										<div className="attribute">
											<p className="m-0">x</p>
										</div>
									</div>
								</div>
								<div className="s4-w3-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">w</p>
										</div>
									</div>
								</div>
								<div className="s4-w4-a">
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">revise, practise, apply</p>
									</div>
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">assess</p>
									</div>
								</div>

								<div className="stage-plus4">
									<strong>Stage +4</strong>
								</div>
								<div className="stage-plus4-2">
									<strong>Stage +4</strong>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '+4', '1')}
									className="btn s4plus-w1 fw-semibold body-small"
								>
									Week 1
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '+4', '2')}
									className="btn s4plus-w2 fw-semibold body-small"
								>
									Week 2
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '+4', '3')}
									className="btn s4plus-w3 fw-semibold body-small"
								>
									Week 3
								</button>
								<div className="s4plus-w1-a flex-column">
									<div className="d-flex gap-1">
										<div className="attribute-wrapper">
											<div className="attribute">
												<p className="m-0">ll</p>
											</div>
											<div className="attribute">
												<p className="m-0">ss</p>
											</div>
											<div className="attribute">
												<p className="m-0">ff</p>
											</div>
										</div>
									</div>
									<div className="d-flex gap-2">
										<div className="attribute-wrapper">
											<div className="attribute blank">
												<p className="m-0">zz</p>
											</div>
										</div>
									</div>
								</div>
								<div className="s4plus-w3-a">
									<div className="revise stacked-group">
										<p className="m-0 fw-semibold body-tiny">
											<strong>morphology</strong>
										</p>
										<div className="phoneme-wrapper">
											<p className="phoneme-icon body-tiny m-0 ">-s</p>
											<p className="phoneme-icon body-tiny m-0">-es</p>
										</div>
									</div>
								</div>

								<div className="stage-5">
									<strong>Stage 5</strong>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '5', '1')}
									className="btn s5-w1 fw-semibold body-small"
								>
									Week 1
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '5', '2')}
									className="btn s5-w2 fw-semibold body-small"
								>
									Week 2
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '5', '3')}
									className="btn s5-w3 fw-semibold body-small"
								>
									Week 3
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '5', '4')}
									className="btn s5-w4 fw-semibold body-small"
								>
									Week 4
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '5', '5')}
									className="btn s5-w5 fw-semibold body-small"
								>
									Week 5
								</button>
								<div className="s5-w1-a">
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">adjacent consonants</p>
									</div>
									<div className="d-flex gap-1 revise revise-wrapper">
										<div className="revise">
											<p className="m-0 fw-semibold body-tiny">CVCC</p>
										</div>
										<div className="revise">
											<p className="m-0 fw-semibold body-tiny">CCVC</p>
										</div>
										<div className="revise">
											<p className="m-0 fw-semibold body-tiny">CCVCC</p>
										</div>
									</div>
								</div>
								<div className="s5-w4-a">
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">multisyllabic words</p>
									</div>
									<div className="revise stacked-group">
										<p className="m-0 fw-semibold body-tiny">
											<strong>morphology</strong>
										</p>
										<div className="phoneme-wrapper">
											<p className="phoneme-icon body-tiny m-0">-ed</p>
										</div>
									</div>
								</div>
								<div className="s5-w5-a">
									<div className="attribute">
										<p className="m-0">ck</p>
									</div>
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">possessive apostrophes</p>
									</div>
								</div>

								<div className="stage-6">
									<strong>Stage 6</strong>
								</div>
								<div className="stage-6-2">
									<strong>Stage 6</strong>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '6', '1')}
									className="btn s6-w1 fw-semibold body-small"
								>
									Week 1
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '6', '2')}
									className="btn s6-w2 fw-semibold body-small"
								>
									Week 2
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '6', '3')}
									className="btn s6-w3 fw-semibold body-small"
								>
									Week 3
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '6', '4')}
									className="btn s6-w4 fw-semibold body-small"
								>
									Week 4
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '6', '5')}
									className="btn s6-w5 fw-semibold body-small"
								>
									Week 5
								</button>
								<div className="s6-w1-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">sh</p>
										</div>
									</div>
								</div>
								<div className="s6-w2-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">ch</p>
										</div>
										<div className="attribute">
											<p className="m-0">tch</p>
										</div>
									</div>
								</div>
								<div className="s6-w3-a">
									<div className="attribute">
										<p className="m-0">th</p>
									</div>
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">exclamation marks</p>
									</div>
								</div>
								<div className="s6-w4-a">
									<div className="attribute">
										<p className="m-0">ng</p>
									</div>
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">question marks</p>
									</div>
								</div>
								<div className="s6-w5-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">ph</p>
										</div>
										<div className="attribute">
											<p className="m-0">wh</p>
										</div>
									</div>
								</div>

								<div className="stage-7">
									<strong>Stage 7.1</strong>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.1', '1')}
									className="btn s7-w1 fw-semibold body-small"
								>
									Week 1
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.1', '2')}
									className="btn s7-w2 fw-semibold body-small"
								>
									Week 2
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.1', '3')}
									className="btn s7-w3 fw-semibold body-small"
								>
									Week 3
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.1', '4')}
									className="btn s7-w4 fw-semibold body-small"
								>
									Week 4
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.1', '5')}
									className="btn s7-w5 fw-semibold body-small"
								>
									Week 5
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.1', '6')}
									className="btn s7-w6 fw-semibold body-small"
								>
									Week 6
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.1', '7')}
									className="btn s7-w7 fw-semibold body-small"
								>
									Week 7
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.1', '8')}
									className="btn s7-w8 fw-semibold body-small"
								>
									Week 8
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.1', '9')}
									className="btn s7-w9 fw-semibold body-small"
								>
									Week 9
								</button>
								<div className="s7-w1-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">ai</p>
										</div>
										<div className="attribute">
											<p className="m-0">ay</p>
										</div>
									</div>
								</div>
								<div className="s7-w2-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">ee</p>
										</div>
										<div className="attribute">
											<p className="m-0">ea</p>
										</div>
									</div>
								</div>
								<div className="s7-w3-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">ˍˍy</p>
										</div>
									</div>
								</div>
								<div className="s7-w4-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">igh</p>
										</div>
									</div>
								</div>
								<div className="s7-w5-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">ˍy</p>
										</div>
										<div className="attribute">
											<p className="m-0">ie</p>
										</div>
									</div>
								</div>
								<div className="s7-w6-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">oa</p>
										</div>
									</div>
								</div>
								<div className="s7-w7-a">
									<div className="revise stacked-group">
										<p className="m-0 fw-semibold body-tiny">
											<strong>morphology</strong> <br />
										</p>
										<div className="phoneme-wrapper">
											<p className="phoneme-icon body-tiny m-0">-ing</p>
										</div>
									</div>
								</div>
								<div className="s7-w8-a">
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">revise, practise, apply</p>
									</div>
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">assess</p>
									</div>
								</div>
							</div>
							<Footnote />
						</>
					) : null}
					{activeTab === 'Year 1' ? (
						<>
							<div className="sequence-wrapper year-1">
								<div className="revision">
									<strong>Revision</strong>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, 'revision', '1')}
									className="btn r-w1 fw-semibold body-small"
								>
									Week 1
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, 'revision', '2')}
									className="btn r-w2 fw-semibold body-small"
								>
									Week 2
								</button>
								<div className="r-w1-a">
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">revise stages 1–6</p>
									</div>
								</div>
								<div className="stage-7-1">
									<strong>Stage 7.1</strong>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.1', '1')}
									className="btn s7-1-w1 fw-semibold body-small"
								>
									Week 1
								</button>
								<div className="s7-1-w1-a">
									<div className="d-flex gap-1">
										<div className="attribute-wrapper">
											<div className="attribute">
												<p className="m-0">ai</p>
											</div>
											<div className="attribute">
												<p className="m-0">ay</p>
											</div>
										</div>
									</div>
									<div className="d-flex gap-2">
										<div className="attribute-wrapper">
											<div className="attribute blank reading">
												<p className="m-0">eigh</p>
											</div>

											<div className="attribute blank reading">
												<p className="m-0">ey</p>
											</div>
										</div>
									</div>
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">contractions</p>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.1', '2')}
									className="btn s7-1-w2 fw-semibold body-small"
								>
									Week 2
								</button>
								<div className="s7-1-w2-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">ee</p>
										</div>
										<div className="attribute">
											<p className="m-0">ea</p>
										</div>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.1', '3')}
									className="btn s7-1-w3 fw-semibold body-small"
								>
									Week 3
								</button>
								<div className="s7-1-w3-a">
									<div className="attribute">
										<p className="m-0">ˍˍy</p>
									</div>
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">homophones</p>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.1', '4')}
									className="btn s7-1-w4 fw-semibold body-small"
								>
									Week 4
								</button>
								<div className="s7-1-w4-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">igh</p>
										</div>
										<div className="attribute">
											<p className="m-0">ie</p>
										</div>
										<div className="attribute">
											<p className="m-0">ˍy</p>
										</div>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.1', '5')}
									className="btn s7-1-w5 fw-semibold body-small"
								>
									Week 5
								</button>
								<div className="s7-1-w5-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">oa</p>
										</div>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.1', '6')}
									className="btn s7-1-w6 fw-semibold body-small"
								>
									Week 6
								</button>
								<div className="s7-1-w6-a">
									<div className="revise stacked-group">
										<p className="m-0 fw-semibold body-tiny">
											<strong>morphology</strong>
										</p>
										<div className="phoneme-wrapper">
											<p className="phoneme-icon body-tiny m-0">-ing</p>
										</div>
										<p className="m-0 fw-semibold body-tiny">
											<strong>& doubling rule</strong>
										</p>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.1', '7')}
									className="btn s7-1-w7 fw-semibold body-small"
								>
									Week 7
								</button>
								<div className="s7-1-w7-a">
									<div className="revise stacked-group">
										<p className="m-0 fw-semibold body-tiny">
											<strong>morphology</strong>
										</p>
										<div className="phoneme-wrapper">
											<p className="phoneme-icon body-tiny m-0">un-</p>
										</div>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.1', '8')}
									className="btn s7-1-w8 fw-semibold body-small"
								>
									Week 8
								</button>
								<div className="s7-1-w8-a">
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">
											revise,
											<br />
											practise,
											<br />
											apply
										</p>
									</div>
								</div>
								<div className="stage-7-2">
									<strong>Stage 7.2</strong>
								</div>
								<div className="stage-7-3">
									<strong>Stage 7.3</strong>
								</div>
								<div className="stage-7-3-2">
									<strong>Stage 7.3</strong>
								</div>
								<div className="stage-7-4">
									<strong>Stage 7.4</strong>
								</div>
								<div className="stage-7-4-2">
									<strong>Stage 7.4</strong>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.2', '1')}
									className="btn s7-2-w1 fw-semibold body-small"
								>
									Week 1
								</button>
								<div className="s7-2-w1-a d-flex flex-column flex-nowrap">
									<div className="attribute">
										<p className="m-0">ar</p>
									</div>
									<div className="d-flex flex-column gap-2">
										<div className="attribute blank reading">
											<p className="m-0">a</p>
										</div>
										<div className="attribute blank reading">
											<p className="m-0">al</p>
										</div>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.2', '2')}
									className="btn s7-2-w2 fw-semibold body-small"
								>
									Week 2
								</button>
								<div className="s7-2-w2-a">
									<div className="attribute-wrapper flex-column row-gap-2">
										<div className="attribute-wrapper">
											<div className="attribute">
												<p className="m-0">or</p>
											</div>
											<div className="attribute">
												<p className="m-0">aw</p>
											</div>
										</div>
										<div className="d-flex gap-2 flex-wrap justify-content-center">
											<div className="d-flex gap-2 flex-wrap justify-content-center">
												<div className="attribute blank reading">
													<p className="m-0">ore</p>
												</div>
												<div className="attribute blank reading">
													<p className="m-0">our</p>
												</div>
											</div>
											<div className="d-flex gap-2 flex-wrap justify-content-center">
												<div className="attribute blank reading">
													<p className="m-0">al</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.2', '3')}
									className="btn s7-2-w3 fw-semibold body-small"
								>
									Week 3
								</button>
								<div className="s7-2-w3-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">ir</p>
										</div>
										<div className="attribute">
											<p className="m-0">ur</p>
										</div>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.2', '4')}
									className="btn s7-2-w4 fw-semibold body-small"
								>
									Week 4
								</button>
								<div className="s7-2-w4-a">
									<div className="attribute">
										<p className="m-0">er</p>
									</div>
									<div className="revise stacked-group">
										<p className="m-0 fw-semibold body-tiny">
											<strong>morphology</strong>
										</p>
										<div className="phoneme-wrapper">
											<p className="phoneme-icon body-tiny m-0">-er</p>
										</div>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.2', '5')}
									className="btn s7-2-w5 fw-semibold body-small"
								>
									Week 5
								</button>
								<div className="s7-2-w5-a">
									<div className="attribute-wrapper flex-column row-gap-2">
										<div className="attribute-wrapper">
											<div className="attribute">
												<p className="m-0">air</p>
											</div>
										</div>
										<div className="d-flex gap-2">
											<div className="attribute blank reading">
												<p className="m-0">are</p>
											</div>
											<div className="attribute blank reading">
												<p className="m-0">ere</p>
											</div>
										</div>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.2', '6')}
									className="btn s7-2-w6 fw-semibold body-small"
								>
									Week 6
								</button>
								<div className="s7-2-w6-a">
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">revise, practise, apply</p>
									</div>
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">assess</p>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.2', '7')}
									className="btn s7-2-w7 fw-semibold body-small"
								>
									Week 7
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.3', '1')}
									className="btn s7-3-w1 fw-semibold body-small"
								>
									Week 1
								</button>
								<div className="s7-3-w1-a">
									<div className="d-flex flex-wrap justify-content-center row-gap-1 column-gap-3">
										<div className="attribute">
											<p className="m-0">oo</p>
										</div>
										<div className="d-flex gap-3">
											<div className="attribute blank reading">
												<p className="m-0">ou</p>
											</div>

											<div className="attribute blank reading">
												<p className="m-0">o</p>
											</div>
										</div>
										<div className="attribute">
											<p className="m-0">le</p>
										</div>
									</div>
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">contractions</p>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.3', '2')}
									className="btn s7-3-w2 fw-semibold body-small"
								>
									Week 2
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.3', '3')}
									className="btn s7-3-w3 fw-semibold body-small"
								>
									Week 3
								</button>
								<div className="s7-3-w3-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">ow</p>
										</div>
										<div className="attribute">
											<p className="m-0">ou</p>
										</div>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.3', '4')}
									className="btn s7-3-w4 fw-semibold body-small"
								>
									Week 4
								</button>
								<div className="s7-3-w4-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">oi</p>
										</div>
										<div className="attribute">
											<p className="m-0">oy</p>
										</div>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.3', '5')}
									className="btn s7-3-w5 fw-semibold body-small"
								>
									Week 5
								</button>
								<div className="s7-3-w5-a d-flex flex-column flex-nowrap">
									<div className="attribute">
										<p className="m-0">oo</p>
									</div>
									<div className="d-flex flex-column gap-2">
										<div className="attribute blank reading">
											<p className="m-0">u</p>
										</div>
										<div className="attribute blank reading">
											<p className="m-0">oul</p>
										</div>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.3', '6')}
									className="btn s7-3-w6 fw-semibold body-small"
								>
									Week 6
								</button>
								<div className="s7-3-w6-a">
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">
											revise,
											<br />
											practise,
											<br />
											apply
										</p>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.4', '1')}
									className="btn s7-4-w1 fw-semibold body-small"
								>
									Week 1
								</button>
								<div className="s7-4-w1-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">a-e</p>
										</div>
										<div className="attribute">
											<p className="m-0">a</p>
										</div>
										<div className="attribute">
											<p className="m-0">
												c<span className="text-shades-400">e</span>
											</p>
										</div>
										<div className="attribute">
											<p className="m-0">
												g<span className="text-shades-400">e</span>
											</p>
										</div>
									</div>
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">homophones</p>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.4', '2')}
									className="btn s7-4-w2 fw-semibold body-small"
								>
									Week 2
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.4', '3')}
									className="btn s7-4-w3 fw-semibold body-small"
								>
									Week 3
								</button>
								<div className="s7-4-w3-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">i-e</p>
										</div>
										<div className="attribute">
											<p className="m-0">i</p>
										</div>
										<div className="attribute">
											<p className="m-0">
												c<span className="text-shades-400">i</span> c<span className="text-shades-400">y</span>
											</p>
										</div>
										<div className="attribute">
											<p className="m-0">
												g<span className="text-shades-400">i</span> g<span className="text-shades-400">y</span>
											</p>
										</div>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.4', '4')}
									className="btn s7-4-w4 fw-semibold body-small"
								>
									Week 4
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.4', '5')}
									className="btn s7-4-w5 fw-semibold body-small"
								>
									Week 5
								</button>
								<div className="s7-4-w5-a">
									<div className="attribute-wrapper">
										<div className="attribute">
											<p className="m-0">e-e</p>
										</div>
										<div className="attribute">
											<p className="m-0">e</p>
										</div>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.4', '6')}
									className="btn s7-4-w6 fw-semibold body-small"
								>
									Week 6
								</button>
								<div className="s7-4-w6-a">
									<div className="d-flex flex-column gap-2">
										<div className="attribute-wrapper">
											<div className="attribute">
												<p className="m-0">o-e</p>
											</div>
											<div className="attribute">
												<p className="m-0">o</p>
											</div>
											<div className="attribute">
												<p className="m-0">ow</p>
											</div>
										</div>
										<div className="d-flex gap-3 justify-content-center">
											<div className="attribute blank reading">
												<p className="m-0">oe</p>
											</div>
											<div className="attribute blank reading">
												<p className="m-0">kn gn</p>
											</div>
											<div className="attribute blank reading">
												<p className="m-0">ch</p>
											</div>
										</div>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.4', '7')}
									className="btn s7-4-w7 fw-semibold body-small"
								>
									Week 7
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.4', '8')}
									className="btn s7-4-w8 fw-semibold body-small"
								>
									Week 8
								</button>
								<div className="s7-4-w8-a">
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">
											revise,
											<br />
											practise,
											<br />
											apply
										</p>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.4', '9')}
									className="btn s7-4-w9 fw-semibold body-small"
								>
									Week 9
								</button>
								<div className="s7-4-w9-a">
									<div className="attribute-wrapper flex-column row-gap-2">
										<div className="attribute blank">
											<p className="m-0">/yŌ/</p>
										</div>
										<div className="attribute-wrapper">
											<div className="attribute">
												<p className="m-0">u-e</p>
											</div>
											<div className="attribute">
												<p className="m-0">ue</p>
											</div>
											<div className="attribute">
												<p className="m-0">ew</p>
											</div>
											<div className="attribute">
												<p className="m-0">u</p>
											</div>
										</div>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.4', '10')}
									className="btn s7-4-w10 fw-semibold body-small"
								>
									Week 10
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.4', '11')}
									className="btn s7-4-w11 fw-semibold body-small"
								>
									Week 11
								</button>
								<div className="s7-4-w11-a">
									<div className="attribute-wrapper flex-column row-gap-2">
										<div className="attribute blank w-100">
											<p className="m-0">/Ō/</p>
										</div>
										<div className="attribute-wrapper">
											<div className="attribute">
												<p className="m-0">u-e</p>
											</div>
											<div className="attribute">
												<p className="m-0">ue</p>
											</div>
											<div className="attribute">
												<p className="m-0">ew</p>
											</div>
											<div className="attribute">
												<p className="m-0">ui</p>
											</div>
										</div>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.4', '12')}
									className="btn s7-4-w12 fw-semibold body-small"
								>
									Week 12
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.4', '13')}
									className="btn s7-4-w13 fw-semibold body-small"
								>
									Week 13
								</button>
								<div className="s7-4-w13-a">
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">
											<strong>morphology</strong> drop the 'e' rule
										</p>
									</div>
									<div className="d-flex gap-3">
										<div className="attribute blank reading">
											<p className="m-0">mb</p>
										</div>
										<div className="attribute blank reading">
											<p className="m-0">wr</p>
										</div>
									</div>
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">synonyms & antonyms</p>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.4', '14')}
									className="btn s7-4-w14 fw-semibold body-small"
								>
									Week 14
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.4', '15')}
									className="btn s7-4-w15 fw-semibold body-small"
								>
									Week 15
								</button>
								<div className="s7-4-w15-a">
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">revise, practise, apply</p>
									</div>
									<div className="revise">
										<p className="m-0 fw-semibold body-tiny">assess</p>
									</div>
								</div>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.4', '16')}
									className="btn s7-4-w16 fw-semibold body-small"
								>
									Week 16
								</button>
								<button
									type="button"
									onClick={() => onClickWeek(activeTab, '7.4', '17')}
									className="btn s7-4-w17 fw-semibold body-small"
								>
									Week 17
								</button>
							</div>
							<Footnote />
						</>
					) : null}
					{activeTab === 'Year 2' ? (
						<div className="sequence-wrapper year-2">
							<div className="stage-7">
								<strong>Stage 7 Review</strong>
							</div>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7 Review', '1')}
								className="s7-w1 btn fw-semibold body-small"
							>
								Week 1
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7 Review', '2')}
								className="s7-w2 btn fw-semibold body-small"
							>
								Week 2
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7 Review', '3')}
								className="s7-w3 btn fw-semibold body-small"
							>
								Week 3
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7 Review', '4')}
								className="s7-w4 btn fw-semibold body-small"
							>
								Week 4
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7 Review', '5')}
								className="s7-w5 btn fw-semibold body-small"
							>
								Week 5
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7 Review', '6')}
								className="s7-w6 btn fw-semibold body-small"
							>
								Week 6
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7 Review', '7')}
								className="s7-w7 btn fw-semibold body-small"
							>
								Week 7
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7 Review', '8')}
								className="s7-w8 btn fw-semibold body-small"
							>
								Week 8
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7 Review', '9')}
								className="s7-w9 btn fw-semibold body-small"
							>
								Week 9
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7 Review', '10')}
								className="s7-w10 btn fw-semibold body-small"
							>
								Week 10
							</button>

							<div className="s7-w1-a">
								<div className="revise">
									<p className="m-0 fw-semibold body-tiny">
										multisyllabic <br /> words
									</p>
								</div>
							</div>
							<div className="s7-w2-a d-flex flex-column items-center">
								<div className="attribute blank">
									<p className="m-0">/ā/</p>
								</div>
								<div className="attribute-wrapper">
									<div className="attribute">
										<p className="m-0">ai</p>
									</div>
									<div className="attribute">
										<p className="m-0">ay</p>
									</div>
									<div className="attribute">
										<p className="m-0">a-e</p>
									</div>
									<div className="attribute">
										<p className="m-0">a</p>
									</div>
								</div>
								<div className="attribute-wrapper">
									<div className="attribute blank reading">
										<p className="m-0">eigh</p>
									</div>
									<div className="attribute blank reading">
										<p className="m-0">ey</p>
									</div>
								</div>
								<div className="attribute blank">
									<p className="m-0">/oi/</p>
								</div>
								<div className="attribute-wrapper">
									<div className="attribute">
										<p className="m-0">oi</p>
									</div>
									<div className="attribute">
										<p className="m-0">oy</p>
									</div>
								</div>
							</div>
							<div className="s7-w3-a d-flex flex-column item-center">
								<div className="attribute blank">
									<p className="m-0">/ē/</p>
								</div>
								<div className="attribute-wrapper">
									<div className="attribute">
										<p className="m-0">ee</p>
									</div>
									<div className="attribute">
										<p className="m-0">ea</p>
									</div>
									<div className="attribute">
										<p className="m-0">ˍˍy</p>
									</div>
									<div className="attribute">
										<p className="m-0">e-e</p>
									</div>
									<div className="attribute">
										<p className="m-0">e</p>
									</div>
								</div>
							</div>
							<div className="s7-w4-a d-flex flex-column items-center">
								<div className="attribute blank">
									<p className="m-0">/ī/</p>
								</div>
								<div className="attribute-wrapper">
									<div className="attribute">
										<p className="m-0">igh</p>
									</div>
									<div className="attribute">
										<p className="m-0">ˍy</p>
									</div>
									<div className="attribute">
										<p className="m-0">ie</p>
									</div>
									<div className="attribute">
										<p className="m-0">i-e</p>
									</div>
									<div className="attribute">
										<p className="m-0">i</p>
									</div>
								</div>
							</div>
							<div className="s7-w5-a d-flex flex-column items-center">
								<div className="attribute blank">
									<p className="m-0">/ō/</p>
								</div>
								<div className="attribute-wrapper">
									<div className="attribute">
										<p className="m-0">oa</p>
									</div>
									<div className="attribute">
										<p className="m-0">o-e</p>
									</div>
									<div className="attribute">
										<p className="m-0">o</p>
									</div>
									<div className="attribute">
										<p className="m-0">ow</p>
									</div>
								</div>
								<div className="attribute-wrapper">
									<div className="attribute blank reading">
										<p className="m-0">oe</p>
									</div>
								</div>

								<div className="attribute-wrapper">
									<div className="attribute blank">
										<p className="m-0">/ow/</p>
									</div>
								</div>

								<div className="attribute-wrapper">
									<div className="attribute">
										<p className="m-0">ou</p>
									</div>
									<div className="attribute">
										<p className="m-0">ow</p>
									</div>
								</div>
							</div>
							<div className="s7-w6-a d-flex flex-column items-center">
								<div className="attribute blank">
									<p className="m-0">/Ō/</p>
								</div>
								<div className="attribute-wrapper">
									<div className="attribute">
										<p className="m-0">oo</p>
									</div>
									<div className="attribute">
										<p className="m-0">u-e</p>
									</div>
									<div className="attribute">
										<p className="m-0">ue</p>
									</div>
									<div className="attribute">
										<p className="m-0">ew</p>
									</div>
									<div className="attribute">
										<p className="m-0">ui</p>
									</div>
								</div>
								<div className="attribute blank">
									<p className="m-0">/yŌ/</p>
								</div>
								<div className="attribute-wrapper">
									<div className="attribute">
										<p className="m-0">u-e</p>
									</div>
									<div className="attribute">
										<p className="m-0">ue</p>
									</div>
									<div className="attribute">
										<p className="m-0">ew</p>
									</div>
									<div className="attribute">
										<p className="m-0">u</p>
									</div>
								</div>
								<div className="attribute blank">
									<p className="m-0">/Ŏ/</p>
								</div>
								<div className="attribute-wrapper">
									<div className="attribute">
										<p className="m-0">oo</p>
									</div>
								</div>
								<div className="attribute-wrapper">
									<div className="attribute blank reading">
										<p className="m-0">u</p>
									</div>
									<div className="attribute blank reading">
										<p className="m-0">oul</p>
									</div>
								</div>
							</div>
							<div className="s7-w8-a d-flex flex-column items-center">
								<div className="attribute blank">
									<p className="m-0">/k/</p>
								</div>
								<div className="attribute-wrapper">
									<div className="attribute phoneme teal">
										<p className="m-0">ck</p>
									</div>
									<div className="attribute">
										<p className="m-0">ch</p>
									</div>
								</div>
								<div className="attribute blank">
									<p className="m-0">/ch/</p>
								</div>
								<div className="attribute-wrapper">
									<div className="attribute phoneme">
										<p className="m-0">ch</p>
									</div>
									<div className="attribute phoneme">
										<p className="m-0">tch</p>
									</div>
								</div>
								<div className="attribute blank">
									<p className="m-0">/s/</p>
								</div>
								<div className="attribute-wrapper">
									<div className="attribute">
										<p className="m-0">
											c<span className="text-shades-400">e</span> c<span className="text-shades-400">i</span> c
											<span className="text-shades-400">y</span>
										</p>
									</div>
								</div>
								<div className="attribute-wrapper">
									<div className="attribute blank reading">
										<p className="m-0">ce</p>
									</div>
									<div className="attribute blank reading">
										<p className="m-0">se</p>
									</div>
								</div>
							</div>
							<div className="s7-w9-a">
								<div className="revise d-flex flex-column items-center gap-1">
									<p className="m-0 fw-semibold body-tiny">morphology</p>
									<div className="d-flex flex-row gap-1">
										<div className="phoneme-icon">-s</div>
										<div className="phoneme-icon">-es</div>
										<div className="phoneme-icon">-ing</div>
										<div className="phoneme-icon">-er</div>
										<div className="phoneme-icon">un-</div>
									</div>
									<p className="m-0 fw-semibold body-tiny mt-2-5">
										doubling rule <br /> & drop the ‘e’ rule
									</p>
								</div>
							</div>

							<div className="stage-7-5">
								<strong>Stage 7.5</strong>
							</div>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7.5', '1')}
								className="s7-5-w1 btn fw-semibold body-small"
							>
								Week 1
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7.5', '2')}
								className="s7-5-w2 btn fw-semibold body-small"
							>
								Week 2
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7.5', '3')}
								className="s7-5-w3 btn fw-semibold body-small"
							>
								Week 3
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7.5', '4')}
								className="s7-5-w4 btn fw-semibold body-small"
							>
								Week 4
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7.5', '5')}
								className="s7-5-w5 btn fw-semibold body-small"
							>
								Week 5
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7.5', '6')}
								className="s7-5-w6 btn fw-semibold body-small"
							>
								Week 6
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7.5', '7')}
								className="s7-5-w7 btn fw-semibold body-small"
							>
								Week 7
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7.5', '8')}
								className="s7-5-w8 btn fw-semibold body-small"
							>
								Week 8
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7.5', '9')}
								className="s7-5-w9 btn fw-semibold body-small"
							>
								Week 9
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7.5', '10')}
								className="s7-5-w10 btn fw-semibold body-small"
							>
								Week 10
							</button>
							<div className="s7-5-w1-a">
								<div className="d-flex flex-column items-center gap-1 revise">
									<p className="m-0 fw-semibold body-tiny">morphology</p>
									<div className="d-flex flex-row gap-1">
										<div className="phoneme-icon">-ed</div>
									</div>
								</div>
							</div>
							<div className="s7-5-w2-a">
								<div className="d-flex flex-column items-center gap-1 revise">
									<p className="m-0 fw-semibold body-tiny">morphology</p>
									<div className="d-flex flex-row gap-1">
										<div className="phoneme-icon">pre-</div>
										<div className="phoneme-icon">re-</div>
									</div>
								</div>
							</div>
							<div className="s7-5-w3-a d-flex flex-column items-center gap-1">
								<div className="d-flex flex-column items-center gap-1 revise">
									<p className="m-0 fw-semibold body-tiny">morphology</p>
									<div className="d-flex flex-row gap-1">
										<div className="phoneme-icon">-ure</div>
									</div>
								</div>
								<div className="d-flex flex-column items-center gap-1 mt-2-5 mb-2-5">
									<div className="attribute blank">
										<p className="m-0">/e/</p>
									</div>
									<div className="attribute">
										<p className="m-0">ea</p>
									</div>
								</div>
							</div>
							<div className="s7-5-w5-a">
								<div className="d-flex flex-column items-center gap-1 revise">
									<p className="m-0 fw-semibold body-tiny">morphology</p>
									<div className="d-flex flex-row gap-1">
										<div className="phoneme-icon">-ion</div>
									</div>
								</div>
							</div>
							<div className="s7-5-w7-a">
								<div className="d-flex flex-column items-center gap-1 revise">
									<p className="m-0 fw-semibold body-tiny">
										revise,
										<br /> practise,
										<br /> apply
									</p>
								</div>
								<div className="d-flex flex-column items-center gap-1 revise">
									<p className="m-0 fw-semibold body-tiny">assess</p>
								</div>
							</div>
							<div className="s7-5-w8-a">
								<div className="d-flex flex-column items-center gap-1 revise">
									<p className="m-0 fw-semibold body-tiny">morphology</p>
									<div className="d-flex flex-row gap-1">
										<div className="phoneme-icon">-ous</div>
									</div>
								</div>
							</div>
							<div className="s7-5-w9-a d-flex flex-column items-center gap-1">
								<div className="attribute blank">
									<p className="m-0">/or/</p>
								</div>
								<div className="attribute-wrapper">
									<div className="attribute">
										<p className="m-0">or</p>
									</div>
									<div className="attribute">
										<p className="m-0">aw</p>
									</div>
								</div>
								<div className="attribute-wrapper">
									<p className="attribute blank reading mb-0">ore</p>
									<p className="attribute blank reading mb-0">our</p>
									<p className="attribute blank reading mb-0">al</p>
									<p className="attribute blank reading mb-0">au</p>
								</div>
								<div className="attribute blank">
									<p className="m-0">/ar/</p>
								</div>
								<div className="attribute-wrapper">
									<div className="attribute">
										<p className="m-0">ar</p>
									</div>
								</div>
								<div className="attribute-wrapper">
									<p className="attribute blank reading mb-0">a</p>
									<p className="attribute blank reading mb-0">al</p>
								</div>
							</div>
							<div className="s7-5-w10-a d-flex flex-column items-center gap-1">
								<div className="attribute blank">
									<p className="m-0">/er/</p>
								</div>
								<div className="attribute-wrapper">
									<div className="attribute">
										<p className="m-0">er</p>
									</div>
									<div className="attribute">
										<p className="m-0">ir</p>
									</div>
									<div className="attribute">
										<p className="m-0">ur</p>
									</div>
								</div>
								<div className="attribute-wrapper">
									<p className="attribute blank reading mb-0">or</p>
									<p className="attribute blank reading mb-0">ear</p>
								</div>
							</div>

							<div className="stage-7-5-2">
								<strong>Stage 7.5</strong>
							</div>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7.5', '11')}
								className="s7-5-2-w11 btn fw-semibold body-small"
							>
								Week 11
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7.5', '12')}
								className="s7-5-2-w12 btn fw-semibold body-small"
							>
								Week 12
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7.5', '13')}
								className="s7-5-2-w13 btn fw-semibold body-small"
							>
								Week 13
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7.5', '14')}
								className="s7-5-2-w14 btn fw-semibold body-small"
							>
								Week 14
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7.5', '15')}
								className="s7-5-2-w15 btn fw-semibold body-small"
							>
								Week 15
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7.5', '16')}
								className="s7-5-2-w16 btn fw-semibold body-small"
							>
								Week 16
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7.5', '17')}
								className="s7-5-2-w17 btn fw-semibold body-small"
							>
								Week 17
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '7.5', '18')}
								className="s7-5-2-w18 btn fw-semibold body-small"
							>
								Week 18
							</button>

							<div className="s7-5-2-w11-a">
								<div className="d-flex flex-column items-center gap-1 revise">
									<p className="m-0 fw-semibold body-tiny">morphology</p>
									<div className="d-flex flex-row gap-1">
										<div className="phoneme-icon">-er</div>
										<div className="phoneme-icon">-est</div>
									</div>
								</div>
							</div>
							<div className="s7-5-2-w12-a d-flex flex-column items-center gap-1">
								<p className="m-0 fw-semibold body-tiny text-center">
									silent <br />
									letters
								</p>
							</div>
							<div className="s7-5-2-w13-a d-flex flex-column items-center gap-1">
								<div className="attribute blank">
									<p className="m-0">/j/</p>
								</div>
								<div className="attribute-wrapper">
									<div className="attribute">
										<p className="m-0">dge</p>
									</div>
									<div className="attribute">
										<p className="m-0">ge</p>
									</div>
									<div className="attribute">
										<p className="m-0">
											g<span className="text-shades-400">e</span> g<span className="text-shades-400">i</span> g
											<span className="text-shades-400">y</span>
										</p>
									</div>
								</div>
								<div className="attribute blank">
									<p className="m-0">/u/</p>
								</div>
								<div className="attribute-wrapper">
									<div className="attribute">
										<p className="m-0">o</p>
									</div>
									<div className="attribute">
										<p className="m-0">ou</p>
									</div>
								</div>
							</div>
							<div className="s7-5-2-w14-a d-flex flex-column items-center gap-1">
								<div className="d-flex flex-column items-center gap-1 revise">
									<p className="m-0 fw-semibold body-tiny">morphology</p>
									<div className="d-flex flex-row gap-1">
										<div className="phoneme-icon">-y</div>
										<div className="phoneme-icon">-ly</div>
									</div>
								</div>
							</div>
							<div className="s7-5-2-w15-a">
								<div className="d-flex flex-column items-center gap-1 revise">
									<p className="m-0 fw-semibold body-tiny">morphology</p>
									<p className="m-0 fw-semibold body-tiny">y to i rule</p>
								</div>
							</div>
							<div className="s7-5-2-w16-a d-flex flex-column items-center gap-1">
								<div className="attribute blank">
									<p className="m-0">/ear/</p>
								</div>
								<div className="attribute-wrapper">
									<div className="attribute">
										<p className="m-0">ear</p>
									</div>
									<div className="attribute">
										<p className="m-0">eer</p>
									</div>
								</div>
								<div className="attribute blank">
									<p className="m-0">/air/</p>
								</div>
								<div className="attribute-wrapper">
									<div className="attribute">
										<p className="m-0">air</p>
									</div>
								</div>
								<div className="attribute-wrapper">
									<p className="attribute blank reading mb-0">are</p>
									<p className="attribute blank reading mb-0">ere</p>
									<p className="attribute blank reading mb-0">ear</p>
								</div>
							</div>
							<div className="s7-5-2-w17-a d-flex flex-column items-center gap-1">
								<div className="attribute blank">
									<p className="m-0">/i/</p>
								</div>
								<div className="attribute-wrapper">
									<div className="attribute">
										<p className="m-0">y</p>
									</div>
								</div>
								<div className="attribute blank">
									<p className="m-0">/o/</p>
								</div>
								<div className="attribute-wrapper">
									<div className="attribute">
										<p className="m-0">a</p>
									</div>
								</div>
							</div>
							<div className="s7-5-2-w18-a">
								<div className="d-flex flex-column items-center gap-1 revise">
									<p className="m-0 fw-semibold body-tiny">
										revise,
										<br /> practise,
										<br /> apply
									</p>
								</div>
							</div>

							<div className="stage-8">
								<strong>Stage 8</strong>
							</div>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '8', '1')}
								className="s8-w1 btn fw-semibold body-small"
							>
								Week 1
							</button>
							<button
								type="button"
								onClick={() => onClickWeek(activeTab, '8', '2')}
								className="s8-w2 btn fw-semibold body-small"
							>
								Week 2
							</button>
							<div className="s8-w1-a">
								<div className="d-flex flex-column items-center gap-1 revise">
									<p className="m-0 fw-semibold body-tiny">morphology</p>
									<div className="d-flex flex-column gap-1">
										<div className="phoneme-icon">-ful</div>
										<div className="phoneme-icon">-less</div>
									</div>
								</div>
							</div>
							<div className="s8-w2-a">
								<div className="d-flex flex-column items-center gap-1 revise">
									<p className="m-0 fw-semibold body-tiny">morphology</p>
									<div className="d-flex flex-column gap-1">
										<div className="phoneme-icon">-ness</div>
										<div className="phoneme-icon">-ment</div>
									</div>
								</div>
							</div>

							<div className="stage-8-2">
								<strong>Stage 8</strong>
							</div>
							<button onClick={() => onClickWeek(activeTab, '8', '3')} className="s8-2-w3 btn fw-semibold body-small">
								Week 3
							</button>
							<button onClick={() => onClickWeek(activeTab, '8', '4')} className="s8-2-w4 btn fw-semibold body-small">
								Week 4
							</button>
							<button onClick={() => onClickWeek(activeTab, '8', '5')} className="s8-2-w5 btn fw-semibold body-small">
								Week 5
							</button>
							<button onClick={() => onClickWeek(activeTab, '8', '6')} className="s8-2-w6 btn fw-semibold body-small">
								Week 6
							</button>
							<button onClick={() => onClickWeek(activeTab, '8', '7')} className="s8-2-w7 btn fw-semibold body-small">
								Week 7
							</button>
							<button onClick={() => onClickWeek(activeTab, '8', '8')} className="s8-2-w8 btn fw-semibold body-small">
								Week 8
							</button>
							<button onClick={() => onClickWeek(activeTab, '8', '9')} className="s8-2-w9 btn fw-semibold body-small">
								Week 9
							</button>
							<button onClick={() => onClickWeek(activeTab, '8', '10')} className="s8-2-w10 btn fw-semibold body-small">
								Week 10
							</button>
							<button onClick={() => onClickWeek(activeTab, '8', '11')} className="s8-2-w11 btn fw-semibold body-small">
								Week 11
							</button>
							<button onClick={() => onClickWeek(activeTab, '8', '12')} className="s8-2-w12 btn fw-semibold body-small">
								Week 12
							</button>

							<div className="s8-2-w3-a">
								<div className="d-flex flex-column items-center gap-1 revise">
									<p className="m-0 fw-semibold body-tiny">morphology</p>
									<div className="d-flex flex-row flex-wrap gap-1 justify-content-center">
										<div className="phoneme-icon">mis-</div>
										<div className="phoneme-icon">dis-</div>
										<div className="phoneme-icon">sub-</div>
									</div>
								</div>
							</div>
							<div className="s8-2-w4-a">
								<div className="d-flex flex-column items-center gap-1 revise">
									<p className="m-0 fw-semibold body-tiny">morphology</p>
									<div className="d-flex flex-row flex-wrap  gap-1 justify-content-center">
										<div className="phoneme-icon">in-</div>
										<div className="phoneme-icon">im-</div>
										<br />
										<div className="phoneme-icon">non-</div>
									</div>
								</div>
							</div>
							<div className="s8-2-w5-a">
								<div className="d-flex flex-column items-center gap-1 revise">
									<p className="m-0 fw-semibold body-tiny">morphology</p>
									<div className="d-flex flex-row flex-wrap gap-1 justify-content-center">
										<div className="phoneme-icon">-er</div>
										<div className="phoneme-icon">-or</div>
									</div>
								</div>
							</div>
							<div className="s8-2-w6-a">
								<div className="d-flex flex-column items-center gap-1 revise">
									<p className="m-0 fw-semibold body-tiny">
										revise,
										<br /> practise,
										<br /> apply
									</p>
								</div>
							</div>
							<div className="s8-2-w7-a d-flex flex-column items-center gap-1">
								<div className="attribute-wrapper mt-2-5 mb-2-5">
									<div className="attribute orange">
										<p className="m-0">le</p>
									</div>
								</div>
								<div className="d-flex flex-column items-center gap-1 revise">
									<p className="m-0 fw-semibold body-tiny">morphology</p>
									<div className="d-flex flex-row flex-wrap gap-1 justify-content-center">
										<div className="phoneme-icon">-al</div>
									</div>
								</div>
							</div>
							<div className="s8-2-w8-a">
								<div className="d-flex flex-column items-center gap-1 revise">
									<p className="m-0 fw-semibold body-tiny">morphology</p>
									<div className="d-flex flex-row flex-wrap gap-1 justify-content-center">
										<div className="phoneme-icon">-ive</div>
										<div className="phoneme-icon">-ish</div>
									</div>
								</div>
							</div>
							<div className="s8-2-w9-a">
								<div className="d-flex flex-column items-center gap-1 revise">
									<p className="m-0 fw-semibold body-tiny">morphology</p>
									<div className="d-flex flex-row flex-wrap gap-1 justify-content-center">
										<div className="phoneme-icon">-able</div>
									</div>
								</div>
							</div>
							<div className="s8-2-w10-a">
								<div className="d-flex flex-column items-center gap-1 revise">
									<p className="m-0 fw-semibold body-tiny">morphology</p>
									<div className="d-flex flex-row flex-wrap gap-1 justify-content-center">
										<div className="phoneme-icon">-en</div>
									</div>
								</div>
							</div>
							<div className="s8-2-w11-a">
								<div className="d-flex flex-column items-center gap-1 revise">
									<p className="m-0 fw-semibold body-tiny">
										revise,
										<br /> practise,
										<br /> apply
									</p>
								</div>
								<div className="d-flex flex-column items-center gap-1 revise">
									<p className="m-0 fw-semibold body-tiny">assess</p>
								</div>
							</div>
						</div>
					) : null}
				</Offcanvas.Body>
			</Offcanvas>
		</div>
	);
};

export default Plans;
