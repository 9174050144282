import { ChangeEvent, FC, SetStateAction, useRef } from 'react';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';

// API
import apibridge from '../../apibridge';

// PACKAGES
import { FormikProps, connect } from 'formik';

// COMPONENTS
import SvgMask from '../_Helpers/SvgMask';

// UTILS
import { useAppDispatch } from '../../store/slice';
import { guid } from '../../libs/utils';
import { Spinner } from 'react-bootstrap';

// TYPES
import { InviteTeachersFormValuesType } from '../../pages/TeacherAdmin/InviteTeachers';

const FileImport: FC<{
	formik: FormikProps<InviteTeachersFormValuesType>;
	submittingTeacherImport: boolean;
	setSubmittingTeacherImport: React.Dispatch<SetStateAction<boolean>>;
}> = ({ formik, submittingTeacherImport, setSubmittingTeacherImport }) => {
	const fileInputRef = useRef<HTMLInputElement>(null);

	const dispatch = useAppDispatch();

	const addToastMessage = (type: 'success' | 'info' | 'danger', heading: string, description: string) => {
		const message = {
			id: guid(),
			type: type,
			heading: heading,
			description: description
		};
		dispatch(ToastMessagesSlice.actions.add(message));
	};

	const handleClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleFileInput = async (e: ChangeEvent<HTMLInputElement>) => {
		if (fileInputRef.current) {
			setSubmittingTeacherImport(true);

			const file = fileInputRef.current.files?.[0];
			if (!file) {
				addToastMessage('danger', 'No file included', 'Please ensure a file has been included to upload.');
				return;
			}

			const response = await apibridge.postStaffUpload(file);
			if (response && response.data) {
				if (!response.data.isError && response.data.result) {
					const { emails } = response.data.result;

					const filteredEmails = emails?.filter(
						(email) => !formik.values.emails.some((existingEmail) => existingEmail.emailAddress === email.emailAddress)
					);

					const validEmails = filteredEmails?.filter((email) => email.status === 'Valid') || [];

					if (filteredEmails?.length !== 0) {
						formik.setFieldValue('emails', [...formik.values.emails, ...(filteredEmails || [])]);
					}

					if (file) {
						fileInputRef.current.value = '';
					}

					if (emails?.some((email) => email.status === 'ProfileExists')) {
						addToastMessage(
							'danger',
							'Existing teachers found',
							'It appears you have added teacher emails that already exist within your organisation. Please remove these email addresses to continue.'
						);
					}

					if (emails?.some((email) => email.status === 'ProfileExistsDifferentOrganisation')) {
						addToastMessage(
							'danger',
							'Existing teachers found',
							'It seems like the teacher you are trying to add exists in another organisation in the platform. A teacher can only be connected to one organisation at a time.'
						);
					}

					if (validEmails.length > 0) {
						addToastMessage(
							'success',
							`Emails successfully imported`,
							`${validEmails.length} emails were successfully imported via Excel!`
						);
					}
				} else if (response.data.validationErrors) {
					for (const err of response.data.validationErrors) {
						dispatch(
							ToastMessagesSlice.actions.add({
								id: guid(),
								type: 'danger',
								heading: 'Import error',
								description: err.reason || 'Unknown error'
							})
						);
					}
				}
			}

			setSubmittingTeacherImport(false);
		}
	};

	return (
		<div className="file-import-container">
			<input
				ref={fileInputRef}
				onChange={handleFileInput}
				type="file"
				name="file"
				id="file"
				className="d-none"
				accept=".xlsx, .xls"
			/>
			<button
				type="button"
				className="btn btn-lg btn-white flex-1 w-100"
				onClick={handleClick}
				disabled={submittingTeacherImport}
			>
				<SvgMask path="/svg/plus.svg" width={16} height={16} />
				Import emails through Excel
				{submittingTeacherImport && <Spinner animation="border" size="sm" />}
			</button>
			<p className="text-shades-500 mb-0">
				To upload emails via Excel, first{' '}
				<a href="/templates/template-teachers.xlsx" target="_blank" download={'template-teachers.xlsx'}>
					download the Excel Template
				</a>
			</p>
		</div>
	);
};

export default connect(FileImport);
