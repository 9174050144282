import { FC, useState, ChangeEvent, useEffect } from 'react';

// API
import apibridge from '../../apibridge';

// REDUX
import { useDispatch } from 'react-redux';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';
import { UserSlice } from '../../store/slice/User';

// PACKAGES
import { Alert, Modal, Spinner } from 'react-bootstrap';
import Moment from 'moment';

// COMPONENTS
import SvgMask from '../_Helpers/SvgMask';
import ManagePONumber from './ManagePONumber';

// UTILS
import { formatPrice, guid } from '../../libs/utils';

// TYPES
import {
	OrganisationSettingsSettingsResult,
	PaymentCalculateUpgradeCommand,
	PaymentCalculateUpgradePaymentCalculate,
	SubscriptionChangeChangeCommandResponse,
	SubscriptionChangeSubscriptionPlans
} from '../../api/models';

const ChangeSubscription: FC<{
	organisationSettings: OrganisationSettingsSettingsResult | undefined;
	getOrganisationSettings: () => Promise<void>;
}> = ({ organisationSettings, getOrganisationSettings }) => {
	const dispatch = useDispatch();

	const [modalToShow, setModalToShow] = useState<
		| 'selectNewSubscription'
		| 'confirmSubscriptionUpgrade'
		| 'confirmSubscriptionDowngrade'
		| 'confirmRevokeSubscriptionDowngrade'
	>();

	const [selectNewSubscriptionSubmitting, setSelectNewSubscriptionSubmitting] = useState(false);
	const [confirmChangeSubscriptionSubmitting, setConfirmChangeSubscriptionSubmitting] = useState(false);
	const [revokeSubscriptionDowngradeSubmitting, setRevokeSubscriptionDowngradeSubmitting] = useState(false);

	const [changeSubscriptionList, setChangeSubscriptionList] = useState<SubscriptionChangeSubscriptionPlans>();
	const [selectedSubscriptionId, setSelectedSubscriptionId] = useState('');
	const [selectedSubscriptionDetails, setSelectedSubscriptionDetails] =
		useState<SubscriptionChangeChangeCommandResponse>();
	const [PONumberField, setPONumberField] = useState(selectedSubscriptionDetails?.orderNumber || '');
	const subscriptionBeingChangedTo = changeSubscriptionList?.plans?.find((plan) => plan.isPendingApply);

	const getChangeSubscriptionList = async () => {
		const response = await apibridge.getSubscriptionChange();
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				setChangeSubscriptionList(response.data.result);
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Subscription list error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}
	};

	const getSelectedSubscriptionDetails = async () => {
		setSelectNewSubscriptionSubmitting(true);

		const response = await apibridge.postSubscriptionChange({
			newSubscriptionId: selectedSubscriptionId
		});
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				setSelectedSubscriptionDetails(response.data.result);
				if (response.data.result.orderNumber) setPONumberField(response.data.result.orderNumber);
				setModalToShow(
					response.data.result.changeType === 'Upgrade' ? 'confirmSubscriptionUpgrade' : 'confirmSubscriptionDowngrade'
				);
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Subscription item details error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setSelectNewSubscriptionSubmitting(false);
	};

	const submitRevokeSubscriptionDowngrade = async () => {
		setRevokeSubscriptionDowngradeSubmitting(true);

		const response = await apibridge.postOrganisationRevokePending();
		if (response && response.data) {
			await getOrganisationSettings();

			// re-fetch the user details and update <ToastNag />
			dispatch(UserSlice.actions.forceUpdate());

			setModalToShow(undefined);
			setChangeSubscriptionList(undefined);
			setSelectedSubscriptionId('');
			dispatch(
				ToastMessagesSlice.actions.add({
					id: guid(),
					type: 'success',
					heading: 'Subscription change revoked',
					description:
						'Your update to a different subscription level has been revoked. You will remain on your current subscription level on your next billing date.'
				})
			);
		}

		setRevokeSubscriptionDowngradeSubmitting(false);
	};

	const submitConfirmChangeSubscription = async () => {
		setConfirmChangeSubscriptionSubmitting(true);

		const submitData =
			isPromoCodeValid && promoCode
				? {
						newSubscriptionId: selectedSubscriptionDetails?.newSubscription?.id,
						orderNumber: selectedSubscriptionDetails?.orderNumber || PONumberField,
						promocode: promoCode
				  }
				: {
						newSubscriptionId: selectedSubscriptionDetails?.newSubscription?.id,
						orderNumber: selectedSubscriptionDetails?.orderNumber || PONumberField
				  };

		const response = await apibridge.postSubscriptionChangeApply(submitData);

		if (response && response.data) {
			await getOrganisationSettings();

			// re-fetch the user details and update any toast nag messages
			dispatch(UserSlice.actions.forceUpdate());

			setModalToShow(undefined);
			setChangeSubscriptionList(undefined);
			setSelectedSubscriptionId('');
			dispatch(
				ToastMessagesSlice.actions.add({
					id: guid(),
					type: 'success',
					heading:
						selectedSubscriptionDetails?.changeType === 'Upgrade'
							? 'Subscription upgrade complete'
							: 'Subscription plan confirmed',
					description:
						selectedSubscriptionDetails?.changeType === 'Upgrade'
							? 'Your subscription has been upgraded. An email has been sent confirming the change.'
							: 'Your update to a new subscription level has been scheduled to come into effect on the next billing date. An email has been sent confirming the change of subscription.'
				})
			);
		}

		setConfirmChangeSubscriptionSubmitting(false);
	};

	// PROMO CODE
	const [promoCode, setPromoCode] = useState('');

	const [isPromoCodeValid, setIsPromoCodeValid] = useState(false);

	const [showPromoCodeInvalidMessage, setShowPromoCodeInvalidMessage] = useState(false);

	const [isPromoCodeSubmitting, setIsPromoCodeSubmitting] = useState(false);

	const initialUpgradeSubscriptionDetails = {
		originalAmount: 0,
		totalAmount: 0,
		amountToPay: 0,
		discount: 0,
		proratedDifference: 0,
		gst: 0
	};

	const [currentUpgradeSubscriptionDetails, setCurrentUpgradeSubscriptionDetails] =
		useState<PaymentCalculateUpgradePaymentCalculate>(initialUpgradeSubscriptionDetails);

	const promoCodeHandler = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();

		setIsPromoCodeSubmitting(true);

		if (!promoCode || !selectedSubscriptionId) return;

		const data: PaymentCalculateUpgradeCommand = {
			subscriptionId: selectedSubscriptionId,
			promocode: promoCode
		};

		const response = await apibridge.postPaymentCalculateUpgrade(data);

		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				setCurrentUpgradeSubscriptionDetails(response.data.result);

				setIsPromoCodeValid(true);

				setShowPromoCodeInvalidMessage(false);
			} else if (response.data.validationErrors) {
				setIsPromoCodeValid(false);

				setShowPromoCodeInvalidMessage(true);

				setCurrentUpgradeSubscriptionDetails(initialUpgradeSubscriptionDetails);
			}
		}

		setIsPromoCodeSubmitting(false);
	};

	const promoCodeRemoveHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();

		setPromoCode('');

		setIsPromoCodeValid(false);

		getSelectedSubscriptionDetails();

		setCurrentUpgradeSubscriptionDetails(initialUpgradeSubscriptionDetails);
	};

	useEffect(() => {
		if (!promoCode) {
			setShowPromoCodeInvalidMessage(false);
		}
	}, [promoCode]);

	return (
		<>
			<div className="col-6 col-xl-4">
				<button type="button" onClick={() => setModalToShow('selectNewSubscription')} className="btn w-100">
					Change subscription
				</button>
			</div>
			<Modal
				show={modalToShow === 'selectNewSubscription'}
				onEnter={() => {
					if (!changeSubscriptionList) {
						getChangeSubscriptionList();
					}
				}}
				onHide={() => setModalToShow(undefined)}
				onExited={() => {
					if (modalToShow === undefined) {
						setSelectedSubscriptionId('');
					}
				}}
				className="modal-change-subscription"
				centered
			>
				<Modal.Body className="gap-4-5">
					<div className="d-flex flex-column gap-3-5">
						<h2 className="m-0 text-shades-800">
							<strong>Change subscription</strong>
						</h2>
						<p className="m-0">Choose an annual subscription that better suits your organisation.</p>
						<p className="m-0">If you have a promo code, it can be applied after selecting a plan.</p>
					</div>

					{changeSubscriptionList?.curent && (
						<div className="d-flex flex-column gap-3-5">
							<h4 className="text-shades-800 m-0">Current subscription</h4>
							<div className="form-selection-wrapper m-0 border-hero-blue">
								<div className="subscription-item text-start">
									<div className="d-flex flex-column gap-2-5 w-100">
										<div className="d-flex align-items-center gap-2">
											<div>
												<strong>{changeSubscriptionList.curent.name}</strong>
											</div>
											<div className="subscription-text-users">
												<strong>
													{changeSubscriptionList.curent.allocations}{' '}
													{Number(changeSubscriptionList.curent.allocations) === 1 ? 'User' : 'Users'}
												</strong>
											</div>
										</div>
										<div className="d-flex align-items-baseline gap-1">
											<span className="body-tiny">
												<strong>AUD</strong>
											</span>
											<span className="h2">
												{changeSubscriptionList.curent?.cost && formatPrice(changeSubscriptionList.curent.cost)}
											</span>
											<span className="body-tiny">
												<strong>per year</strong>
											</span>
											<span className="ms-auto body-tiny text-shades-500">
												<strong>GST Incl.</strong>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}

					{subscriptionBeingChangedTo && (
						<div className="d-flex flex-column gap-3-5">
							<h4 className="text-shades-800 m-0">Next subscription change</h4>
							<div className="form-selection-wrapper m-0 border-hero-blue">
								<div className="subscription-item text-start">
									<div className="d-flex flex-column gap-2-5 w-100">
										<div className="d-flex align-items-center gap-2">
											<div>
												<strong>{subscriptionBeingChangedTo.name}</strong>
											</div>
											<div className="subscription-text-users">
												<strong>
													{subscriptionBeingChangedTo.allocations}{' '}
													{Number(subscriptionBeingChangedTo.allocations) === 1 ? 'User' : 'Users'}
												</strong>
											</div>
										</div>
										<div className="d-flex align-items-baseline gap-1">
											<span className="body-tiny">
												<strong>AUD</strong>
											</span>
											<span className="h2">
												{subscriptionBeingChangedTo?.cost && formatPrice(subscriptionBeingChangedTo.cost)}
											</span>
											<span className="body-tiny">
												<strong>per year</strong>
											</span>
											<span className="ms-auto body-tiny text-shades-500">
												<strong>GST Incl.</strong>
											</span>
										</div>
										<Alert variant="info" className="m-0">
											The subscription change will take effect on{' '}
											<strong>{Moment(organisationSettings?.nextInvoiceDate).format('D MMM, YYYY')}</strong>.
										</Alert>
										<button
											type="button"
											onClick={() => setModalToShow('confirmRevokeSubscriptionDowngrade')}
											className="btn btn-sm btn-white"
										>
											Revoke subscription change
										</button>
									</div>
								</div>
							</div>
						</div>
					)}

					{changeSubscriptionList?.plans?.length ? (
						<div className="d-flex flex-column gap-4-5">
							<div className="d-flex flex-column gap-3-5">
								<h4 className="text-shades-800 m-0">I would like to change to</h4>
								<div className="form-selection-wrapper m-0">
									<div className="form-selection-wrapper-inner gap-0 p-0">
										{changeSubscriptionList.plans
											.filter((plan) => !plan.isPendingApply)
											.map((subscription) => {
												const { allocations, cost, id = '', name } = subscription;
												return (
													<div
														key={id}
														className={`subscription-item text-start ${
															selectedSubscriptionId ? (selectedSubscriptionId === id ? 'active' : 'faded') : ''
														}`}
													>
														<label htmlFor={`subscription-${id}`} className="d-flex flex-column gap-2-5 w-100">
															<div className="d-flex align-items-center gap-2">
																<input
																	type="radio"
																	id={`subscription-${id}`}
																	name="subscription-list"
																	className="form-check-input"
																	onChange={() => setSelectedSubscriptionId(id)}
																	checked={id === selectedSubscriptionId}
																/>
																<div>
																	<strong>{name}</strong>
																</div>
																<div className="subscription-text-users">
																	<strong>
																		{allocations} {Number(allocations) === 1 ? 'User' : 'Users'}
																	</strong>
																</div>
															</div>
															<div className="d-flex align-items-baseline gap-1">
																<span className="body-tiny">
																	<strong>AUD</strong>
																</span>
																<span className="h2">{cost && formatPrice(cost)}</span>
																<span className="body-tiny">
																	<strong>per year</strong>
																</span>
																<span className="ms-auto body-tiny text-shades-500">
																	<strong>GST Incl.</strong>
																</span>
															</div>
														</label>
													</div>
												);
											})}
									</div>
								</div>
							</div>
							<div className="d-flex button-group my-0">
								<button type="button" className="btn btn-white btn-lg w-100" onClick={() => setModalToShow(undefined)}>
									Cancel
								</button>
								<button
									type="button"
									onClick={getSelectedSubscriptionDetails}
									className="btn btn-lg w-100"
									disabled={!selectedSubscriptionId || selectNewSubscriptionSubmitting}
								>
									Select this plan
								</button>
							</div>
						</div>
					) : (
						<div className="d-flex align-items-center justify-content-center position-relative">
							<Spinner animation="border" role="status">
								<span className="visually-hidden">Loading...</span>
							</Spinner>
						</div>
					)}
				</Modal.Body>
			</Modal>

			{/* CONFIRM REVOKE SUBSCRIPTION DOWNGRADE */}
			<Modal
				show={modalToShow === 'confirmRevokeSubscriptionDowngrade'}
				onHide={() => setModalToShow('selectNewSubscription')}
				className="modal-change-subscription"
				centered
			>
				<Modal.Body className="gap-4-5">
					<div className="d-flex flex-column gap-3-5">
						<h2 className="m-0 text-shades-800">
							<strong>Are you sure you want to revoke the subscription change?</strong>
						</h2>
						<p className="m-0">
							This will revoke the subscription changing to a {subscriptionBeingChangedTo?.name} subscription at the
							next billing date. Your organisation will remain on the current {changeSubscriptionList?.curent?.name}{' '}
							subscription.
						</p>
					</div>

					<div className="d-flex flex-column gap-4-5">
						<div className="d-flex button-group my-0">
							<button
								type="button"
								className="btn btn-white btn-lg w-100"
								onClick={() => setModalToShow('selectNewSubscription')}
							>
								Go back
							</button>
							<button
								type="button"
								onClick={submitRevokeSubscriptionDowngrade}
								className="btn btn-lg w-100"
								disabled={revokeSubscriptionDowngradeSubmitting}
							>
								Revoke change
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			{/* SUBSCRIPTION UPGRADE */}
			<Modal
				show={modalToShow === 'confirmSubscriptionUpgrade'}
				onHide={() => setModalToShow('selectNewSubscription')}
				className="modal-change-subscription"
				centered
			>
				<Modal.Body className="gap-4-5">
					<div className="d-flex flex-column gap-3-5">
						<h2 className="m-0 text-shades-800">
							<strong>Confirm subscription change</strong>
						</h2>
					</div>
					<div className="d-flex flex-column gap-3-5">
						<h4 className="text-shades-800 m-0">You have selected</h4>
						<div className="form-selection-wrapper m-0 border-hero-blue">
							<div className="subscription-item text-start">
								<div className="d-flex flex-column gap-2-5 w-100">
									<div className="d-flex align-items-center gap-2">
										<div>
											<strong>{selectedSubscriptionDetails?.newSubscription?.name}</strong>
										</div>
										<div className="subscription-text-users">
											<strong>
												{selectedSubscriptionDetails?.newSubscription?.allocations}{' '}
												{Number(selectedSubscriptionDetails?.newSubscription?.allocations) === 1 ? 'User' : 'Users'}
											</strong>
										</div>
									</div>
									<div className="d-flex align-items-baseline gap-1">
										<span className="body-tiny">
											<strong>AUD</strong>
										</span>
										<span className="h2">
											{selectedSubscriptionDetails?.newSubscription?.cost &&
												formatPrice(selectedSubscriptionDetails.newSubscription.cost)}
										</span>
										<span className="body-tiny">
											<strong>per year</strong>
										</span>
										<span className="ms-auto body-tiny text-shades-500">
											<strong>GST Incl.</strong>
										</span>
									</div>
									<Alert variant="info" className="m-0">
										The upgraded plan will take immediate effect, and you'll be billed the pro-rated difference.
									</Alert>
								</div>
							</div>
						</div>
					</div>
					<div className="d-flex flex-column gap-3-5">
						<h4 className="text-shades-800 m-0">Please review the payment details below.</h4>
						<div className="form-selection-wrapper m-0 border-hero-blue">
							<div className="form-selection-wrapper-inner gap-0 p-0">
								<div className="subscription-item d-flex flex-column gap-3-5 text-start">
									<div className="d-flex flex-column align-items-start gap-2-5">
										<div className="d-flex justify-content-between w-100">
											<div>
												<strong>Payment Method</strong>
											</div>
											<div>
												{selectedSubscriptionDetails?.paymentType === 'Paid'
													? 'Credit Card'
													: selectedSubscriptionDetails?.paymentType === 'Invoiced'
													? 'School Invoice'
													: null}
											</div>
										</div>
										{selectedSubscriptionDetails?.paymentType === 'Invoiced' && (
											<ManagePONumber
												subscriptionDetails={selectedSubscriptionDetails}
												setPONumberField={setPONumberField}
											/>
										)}
									</div>

									{/* PROMO CODE */}
									{selectedSubscriptionDetails?.paymentType === 'Invoiced' && (
										<>
											<hr className="text-light-blue m-0" />

											<div className="form-group">
												<label htmlFor="promoCode" className="form-label">
													Promo Code
												</label>

												{isPromoCodeValid ? (
													<div className="promo-code-box-success">
														<div className="success-info">
															<SvgMask path="/svg/check-success.svg" width={20} height={20} className="icon" />
															<span className="text">
																<strong>{promoCode}</strong> applied
															</span>
														</div>

														<button className="btn btn-remove" onClick={promoCodeRemoveHandler}>
															Remove
														</button>
													</div>
												) : (
													<div className="promo-code-box">
														<input
															id="promoCode"
															type="text"
															value={promoCode}
															onChange={(e: ChangeEvent<HTMLInputElement>) => setPromoCode(e.target.value)}
														/>

														<button
															className="btn btn-primary btn-sm btn-promo"
															onClick={promoCodeHandler}
															disabled={isPromoCodeSubmitting}
														>
															Add
														</button>
													</div>
												)}

												{showPromoCodeInvalidMessage ? (
													<div className="text-danger text-new-line-wrap body-small fw-semibold mt-2">
														Promo Code is invalid.
													</div>
												) : null}
											</div>
										</>
									)}

									<hr className="text-light-blue m-0" />
									<div className="d-flex flex-column align-items-start gap-2-5">
										<div className="d-flex justify-content-between w-100">
											<div>
												<strong>{selectedSubscriptionDetails?.newSubscription?.name} Subscription</strong>
											</div>
											<div>
												<strong>
													{isPromoCodeValid ? (
														<>{formatPrice(currentUpgradeSubscriptionDetails?.originalAmount)}</>
													) : (
														<>
															{selectedSubscriptionDetails?.newSubscription?.cost &&
																formatPrice(selectedSubscriptionDetails.newSubscription?.cost)}
														</>
													)}
												</strong>
											</div>
										</div>
										<div className="d-flex justify-content-between w-100">
											<div>
												<strong>Pro-rated difference</strong>
											</div>
											<div>
												<strong>
													{isPromoCodeValid ? (
														<>
															{/* special formatting to make sure that the '-' appears BEFORE the dollar sign */}
															{`${currentUpgradeSubscriptionDetails?.proratedDifference === 0 ? '' : '-'}${formatPrice(
																currentUpgradeSubscriptionDetails?.proratedDifference
															)}`}
														</>
													) : (
														<>
															{selectedSubscriptionDetails?.proratedDifference &&
																`-${formatPrice(selectedSubscriptionDetails?.proratedDifference)}`}
														</>
													)}
												</strong>
											</div>
										</div>
										{isPromoCodeValid && promoCode && (
											<div className="d-flex justify-content-between w-100">
												<div>
													<strong>{promoCode} applied</strong>
												</div>
												<div>
													<strong>{`-${formatPrice(currentUpgradeSubscriptionDetails?.discount)}`}</strong>
												</div>
											</div>
										)}
									</div>
									<hr className="text-light-blue m-0" />
									<div className="d-flex justify-content-between w-100">
										<div>
											<h3 className="m-0">
												<strong>Total</strong>
											</h3>
										</div>
										<div className="col-auto d-flex flex-column gap-2-5 text-end">
											<h3 className="m-0">
												<strong>
													AUD
													{isPromoCodeValid ? (
														<> {formatPrice(currentUpgradeSubscriptionDetails?.amountToPay)}</>
													) : (
														<> {formatPrice(selectedSubscriptionDetails?.amountToPay)}</>
													)}
												</strong>
											</h3>
											<p className="m-0 text-shades-500">
												<strong>
													Incl. GST
													{isPromoCodeValid ? (
														<> {formatPrice(currentUpgradeSubscriptionDetails?.gst)}</>
													) : (
														<> {formatPrice(selectedSubscriptionDetails?.gst)}</>
													)}
												</strong>
											</p>
										</div>
									</div>
									<div className="d-flex button-group my-0">
										<button
											type="button"
											className="btn btn-white btn-lg w-100"
											onClick={() => setModalToShow('selectNewSubscription')}
										>
											Go back
										</button>
										<button
											type="button"
											onClick={() => submitConfirmChangeSubscription()}
											className="btn btn-lg w-100"
											disabled={confirmChangeSubscriptionSubmitting || isPromoCodeSubmitting}
										>
											{confirmChangeSubscriptionSubmitting ? (
												<Spinner animation="border" size="sm" />
											) : (
												'Confirm change'
											)}
										</button>
									</div>
									<p className="m-0 text-shades-500 body-tiny text-center">
										{selectedSubscriptionDetails?.paymentType === 'Paid'
											? 'You will be redirected to Stripe.'
											: 'An email with the invoice will be sent to the billing email above.'}
									</p>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			{/* SUBSCRIPTION DOWNGRADE */}
			<Modal
				show={modalToShow === 'confirmSubscriptionDowngrade'}
				onHide={() => setModalToShow('selectNewSubscription')}
				className="modal-change-subscription"
				centered
			>
				<Modal.Body className="gap-4-5">
					<div className="d-flex flex-column gap-3-5">
						<h2 className="m-0 text-shades-800">
							<strong>Confirm subscription change</strong>
						</h2>
					</div>
					<div className="d-flex flex-column gap-3-5">
						<h4 className="text-shades-800 m-0">You have selected</h4>
						<div className="form-selection-wrapper m-0 border-hero-blue">
							<div className="subscription-item text-start">
								<div className="d-flex flex-column gap-2-5 w-100">
									<div className="d-flex align-items-center gap-2">
										<div>
											<strong>{selectedSubscriptionDetails?.newSubscription?.name}</strong>
										</div>
										<div className="subscription-text-users">
											<strong>
												{selectedSubscriptionDetails?.newSubscription?.allocations}{' '}
												{Number(selectedSubscriptionDetails?.newSubscription?.allocations) === 1 ? 'User' : 'Users'}
											</strong>
										</div>
									</div>
									<div className="d-flex align-items-baseline gap-1">
										<span className="body-tiny">
											<strong>AUD</strong>
										</span>
										<span className="h2">
											{selectedSubscriptionDetails?.newSubscription?.cost &&
												formatPrice(selectedSubscriptionDetails.newSubscription?.cost)}
										</span>
										<span className="body-tiny">
											<strong>per year</strong>
										</span>
										<span className="ms-auto body-tiny text-shades-500">
											<strong>GST Incl.</strong>
										</span>
									</div>
									<Alert variant="info" className="m-0">
										The selected plan will be reflected on your next invoice and take effect on{' '}
										<strong>{Moment(organisationSettings?.nextInvoiceDate).format('D MMM, YYYY')}</strong>. From now
										until then, your organisation can continue to use your current plan.
									</Alert>
								</div>
							</div>
						</div>
					</div>
					<h4 className="m-0 text-danger">
						Before your next billing date, please ensure you remove any additional users that are not supported in the
						updated subscription plan.
					</h4>
					<div className="d-flex button-group my-0">
						<button
							type="button"
							className="btn btn-white btn-lg w-100"
							onClick={() => setModalToShow('selectNewSubscription')}
						>
							Go back
						</button>
						<button
							type="button"
							onClick={() => submitConfirmChangeSubscription()}
							className="btn btn-lg w-100"
							disabled={confirmChangeSubscriptionSubmitting}
						>
							Confirm change
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ChangeSubscription;
