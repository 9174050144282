/* tslint:disable */
/* eslint-disable */
/**
 * Default API
 * All endpoints not defined under specific APIs
 *
 * OpenAPI spec version: Latest
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AuthenticationChangePasswordCommand } from '../models';
import { AuthenticationForgotPasswordCommand } from '../models';
import { AuthenticationLoginCommand } from '../models';
import { AuthenticationLoginResponse } from '../models';
import { AuthenticationResetPasswordCommand } from '../models';
import { AuthenticationTokenCommand } from '../models';
import { AuthenticationTokenRefreshCommand } from '../models';
import { AuthenticationTokenRefreshResponse } from '../models';
import { AuthenticationTokenResponse } from '../models';
import { SharedModelsCommandResponse } from '../models';
/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AuthenticationChangePasswordCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationChangePasswordPost: async (body?: AuthenticationChangePasswordCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/ChangePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthenticationForgotPasswordCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationForgotPasswordPost: async (body?: AuthenticationForgotPasswordCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/ForgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthenticationLoginCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginPost: async (body?: AuthenticationLoginCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/Login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthenticationResetPasswordCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationResetPasswordPost: async (body?: AuthenticationResetPasswordCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/ResetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthenticationTokenCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationTokenPost: async (body?: AuthenticationTokenCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/Token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthenticationTokenRefreshCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationTokenRefreshPost: async (body?: AuthenticationTokenRefreshCommand, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/TokenRefresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AuthenticationChangePasswordCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationChangePasswordPost(body?: AuthenticationChangePasswordCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).apiAuthenticationChangePasswordPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {AuthenticationForgotPasswordCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationForgotPasswordPost(body?: AuthenticationForgotPasswordCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).apiAuthenticationForgotPasswordPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {AuthenticationLoginCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLoginPost(body?: AuthenticationLoginCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationLoginResponse>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).apiAuthenticationLoginPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {AuthenticationResetPasswordCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationResetPasswordPost(body?: AuthenticationResetPasswordCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedModelsCommandResponse>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).apiAuthenticationResetPasswordPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {AuthenticationTokenCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationTokenPost(body?: AuthenticationTokenCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationTokenResponse>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).apiAuthenticationTokenPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {AuthenticationTokenRefreshCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationTokenRefreshPost(body?: AuthenticationTokenRefreshCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationTokenRefreshResponse>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).apiAuthenticationTokenRefreshPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {AuthenticationChangePasswordCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationChangePasswordPost(body?: AuthenticationChangePasswordCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return AuthenticationApiFp(configuration).apiAuthenticationChangePasswordPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthenticationForgotPasswordCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationForgotPasswordPost(body?: AuthenticationForgotPasswordCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return AuthenticationApiFp(configuration).apiAuthenticationForgotPasswordPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthenticationLoginCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginPost(body?: AuthenticationLoginCommand, options?: any): AxiosPromise<AuthenticationLoginResponse> {
            return AuthenticationApiFp(configuration).apiAuthenticationLoginPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthenticationResetPasswordCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationResetPasswordPost(body?: AuthenticationResetPasswordCommand, options?: any): AxiosPromise<SharedModelsCommandResponse> {
            return AuthenticationApiFp(configuration).apiAuthenticationResetPasswordPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthenticationTokenCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationTokenPost(body?: AuthenticationTokenCommand, options?: any): AxiosPromise<AuthenticationTokenResponse> {
            return AuthenticationApiFp(configuration).apiAuthenticationTokenPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthenticationTokenRefreshCommand} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationTokenRefreshPost(body?: AuthenticationTokenRefreshCommand, options?: any): AxiosPromise<AuthenticationTokenRefreshResponse> {
            return AuthenticationApiFp(configuration).apiAuthenticationTokenRefreshPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @param {AuthenticationChangePasswordCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationChangePasswordPost(body?: AuthenticationChangePasswordCommand, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationChangePasswordPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {AuthenticationForgotPasswordCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationForgotPasswordPost(body?: AuthenticationForgotPasswordCommand, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationForgotPasswordPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {AuthenticationLoginCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLoginPost(body?: AuthenticationLoginCommand, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLoginPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {AuthenticationResetPasswordCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationResetPasswordPost(body?: AuthenticationResetPasswordCommand, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationResetPasswordPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {AuthenticationTokenCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationTokenPost(body?: AuthenticationTokenCommand, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationTokenPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {AuthenticationTokenRefreshCommand} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationTokenRefreshPost(body?: AuthenticationTokenRefreshCommand, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationTokenRefreshPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}
