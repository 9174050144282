import { pushMessageToStore } from '../App';

// PACKAGES
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

// UTILS
import { SITE_KEY, guid } from '../libs/utils';

// API
import {
	AccountApi,
	AdminApi,
	AuthenticationApi,
	BookApi,
	ClassApi,
	HelpCentreApi,
	OrganisationApi,
	ProfessionalLearningApi,
	ResourceApi,
	RoutineApi,
	StaffApi,
	StudentAdminApi,
	StudentApi,
	PlannerApi,
	SubscriptionApi,
	PaymentApi,
	TrialApi
} from '../api/api';
import { checkUserSession, setUserSession } from './checkUserSession';
import { ResponseStandardFail, ResponseStandardSuccess } from '../libs/api';
import {
	AccountCreateCommand,
	AccountCreateResponse,
	AccountEmailChangeCommand,
	AccountMessagesResponse,
	AccountNameChangeCommand,
	AccountPasswordChangeCommand,
	AccountSettingsResponse,
	AccountUserInfoResponse,
	AdminGrantCommand,
	AdminRevokeCommand,
	AuthenticationChangePasswordCommand,
	AuthenticationForgotPasswordCommand,
	AuthenticationLoginCommand,
	AuthenticationResetPasswordCommand,
	AuthenticationTokenCommand,
	AuthenticationTokenRefreshCommand,
	AuthenticationTokenResponse,
	BookFavoriteCommand,
	BookListResponse,
	BookStagesResponse,
	ClassCreateCommand,
	ClassDeleteCommand,
	ClassListResponse,
	ClassViewResponse,
	HelpCentreListResponse,
	OrganisationBillingEditCommand,
	OrganisationBillingEditResponse,
	OrganisationCancelCommand,
	OrganisationEditCommand,
	OrganisationEditResponse,
	OrganisationReactivateCommand,
	OrganisationRegisterCommand,
	OrganisationRegisterCommandResponse,
	OrganisationRegisterCompleteResponse,
	OrganisationRegisterQueryResponse,
	OrganisationSettingsResponse,
	PaymentCalculateCommand,
	PaymentCalculateResponse,
	PaymentCalculateUpgradeCommand,
	PaymentCalculateUpgradeResponse,
	PaymentUpdateDetailsResponse,
	PlannerWeekFindResponse,
	PlannerWeekResponse,
	ProfessionalLearningListResponse,
	ResourceFavoriteCommand,
	ResourceListResponse,
	RoutineGroupsResponse,
	RoutineRoutinesResponse,
	RoutineViewResponse,
	SharedModelsCommandIdResponse,
	SharedModelsCommandResponse,
	StaffBookCompleteCommand,
	StaffBookCompleteResponse,
	StaffBookViewResponse,
	StaffClassAssignCommand,
	StaffClassRemoveCommand,
	StaffDashboardResponse,
	StaffDeleteCommand,
	StaffInviteCommand,
	StaffInviteResendCommand,
	StaffInviteResponse,
	StaffInviteRevokeCommand,
	StaffListResponse,
	StaffUploadResponse,
	StaffViewResponse,
	StudentActivityProgressCommand,
	StudentActivityStartCommand,
	StudentAdminArchiveCommand,
	StudentAdminArchivedResponse,
	StudentAdminCardsCommand,
	StudentAdminCardsResponse,
	StudentAdminClassSwapCommand,
	StudentAdminCreateCommand,
	StudentAdminCreateResponse,
	StudentAdminDashboardResponse,
	StudentAdminDeleteCommand,
	StudentAdminUnarchiveCommand,
	StudentAdminUploadResponse,
	StudentAdminViewResponse,
	StudentAwardsResponse,
	StudentBookCompleteCommand,
	StudentBookCompleteResponse,
	StudentBookProgressCommand,
	StudentBookRatingCommand,
	StudentBookViewResponse,
	StudentLibraryResponse,
	SubscriptionChangeApplyCommand,
	SubscriptionChangeCommand,
	SubscriptionChangeCommandResponse,
	SubscriptionChangeQueryResponse,
	SubscriptionEditCommand,
	SubscriptionListResponse,
	SubscriptionReactivateCancelledApplyCommand,
	SubscriptionReactivateCancelledCommand,
	SubscriptionReactivateCancelledCommandResponse,
	SubscriptionReactivateCancelledQueryResponse,
	TrialCreateCommand,
	TrialRequestCommand,
	TrialRequestResponse
} from '../api/models';
import { AuthenticationLoginResponse } from '../api/models/authentication-login-response';
import { StudentAdminBookUnassignCommand } from '../api/models/student-admin-book-unassign-command';
import { StudentAdminBookAssignCommand } from '../api/models/student-admin-book-assign-command';
import { OrganisationInviteLeftResponse } from '../api/models/organisation-invite-left-response';
import { SystemApi } from '../api/apis/system-api';
import { SystemInfoType } from '../store/slice/SystemInfo';

const apibridge = () => {
	let tokenTimer: NodeJS.Timeout;

	document.cookie = `LLTimezoneOffset=${new Date().getTimezoneOffset()};max-age=${
		30 * 24 * 60 * 60
	};secure;samesite=strict;path=/`;

	const authenticationTokenRefresh = async () => {
		const fields: AuthenticationTokenRefreshCommand = {
			token: localStorage.getItem('token') || '',
			refreshToken: localStorage.getItem('refreshToken') || ''
		};

		return new AuthenticationApi().apiAuthenticationTokenRefreshPost(fields);
	};

	const destroyToken = () => {
		clearTimeout(tokenTimer);
		setUserSession(null);
		localStorage.removeItem('token');
		localStorage.removeItem('refreshToken');
		document.cookie = `AuthorizationToken=;expires=Thu, 01 Jan 1970 00:00:00 GMT;secure;samesite=strict`;
	};

	// initialisation
	// before request
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem('token') || '';

			if (token) {
				config.headers = {
					...config.headers,
					Authorization: `Bearer ${token}`,
					LLTimezoneOffset: new Date().getTimezoneOffset()
				};
			}

			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	// before response
	axios.interceptors.response.use(
		(response: AxiosResponse<ResponseStandardSuccess | ResponseStandardFail>) => {
			// Any status code that lie within the range of 2xx cause this function to trigger

			const { isError } = response.data;
			if (!isError && response.data.result) {
				const { result } = response.data;
				const { cookieExpiry, refreshToken, token } = result;
				if (token) {
					const maxAge = cookieExpiry !== undefined ? cookieExpiry * 60 : 86400;
					setUserSession(token);
					localStorage.setItem('token', token);
					document.cookie = `AuthorizationToken=${token};max-age=${maxAge};secure;samesite=strict;path=/`;

					// should output something reasonable like (30 mins - 5 mins). Or 5 mins if something goes wrong
					const tokenInterval = (maxAge - 300 > 300 ? maxAge - 300 : 300) * 1000; // seconds to milliseconds

					// timeout to run again before the token expires
					clearTimeout(tokenTimer);
					tokenTimer = setTimeout(() => {
						authenticationTokenRefresh();
					}, tokenInterval);
				}

				if (refreshToken) localStorage.setItem('refreshToken', refreshToken);
			}

			// Retry axios request 1 time if recaptcha token is bad
			if (isError && response.data.validationErrors?.length) {
				const originalRequest = response.config as AxiosRequestConfig & { _captchaRetry: boolean };
				const { validationErrors } = response.data;

				const captchaError = validationErrors[0].name === 'CaptchaToken';
				if (captchaError && !originalRequest._captchaRetry) {
					originalRequest._captchaRetry = true;

					const params = JSON.parse(originalRequest.data);

					return new Promise((resolve) => {
						window.grecaptcha.ready(() => {
							window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then((token: string) => {
								params.captchaToken = token;
								originalRequest.data = JSON.stringify(params);
								resolve(axios(originalRequest));
							});
						});
					});
				}
			}

			return response;
		},
		(error: AxiosError<any>) => {
			// Any status codes that falls outside the range of 2xx cause this function to trigger
			if (error.response?.status === 401) {
				const originalRequest = error.config as AxiosRequestConfig & { _retry: boolean };

				if (!originalRequest._retry) {
					originalRequest._retry = true;

					return authenticationTokenRefresh()
						.then((response) => {
							if (response.data.isError) {
								// token refresh failed
								destroyToken();
							}
							return axios(originalRequest);
						})
						.catch(() => {
							// token refresh failed
							destroyToken();
						});
				} else {
					// 2 x 401 error in a row - token refresh failed
					destroyToken();
				}
			}

			return Promise.reject(error);
		}
	);

	function request<T>(apiCall: any): T {
		// check for multiple tabs that are logged in
		checkUserSession();

		return apiCall()
			.then((response: AxiosResponse<ResponseStandardSuccess>) => {
				return response;
			})
			.catch((error: AxiosError<ResponseStandardFail>) => {
				const errorData = {
					data: {}
				};
				const errorDetails: ResponseStandardFail = {
					detail: '',
					errors: [],
					isError: true,
					status: 500,
					type: 'Error',
					validationErrors: []
				};
				let type = '';
				let message = '';

				if (error) {
					if (error.response) {
						// Request made and server responded
						const { data, status, statusText } = error.response;

						// 'title' can exist in error.response.data, but Axios doesn't pick this up
						if ('title' in data && data?.title === 'Forbidden') return;

						type = 'An error has occurred';
						message =
							'Try again, and if it keeps occurring, please contact Little Learners Love Literacy to report the problem.';

						errorData.data = {
							...errorDetails,
							detail: statusText,
							status,
							type: statusText,
							errors: [{ type, message }]
						};
					} else {
						// The request was made but no response was received
						type = 'Response error';
						message = 'A communication error has occurred';

						errorData.data = {
							...errorDetails,
							detail: message,
							errors: [{ type, message }]
						};
					}
				}

				// store.dispatch({	type: 'toastMessages', ...}); will error
				// using a function to push the error message to the store to avoid circular reference logic
				pushMessageToStore({
					description: message,
					heading: type,
					id: guid(),
					type: 'danger'
				});

				console.error(errorData);
				return errorData;
			});
	}

	return {
		// GLOBAL
		async refreshAuthenticationToken() {
			// special case - no need to bind
			return request(authenticationTokenRefresh);
		},

		// ACCOUNT
		async getAccountCreate(field: string) {
			return request<Promise<AxiosResponse<AccountCreateResponse | ResponseStandardFail>>>(
				new AccountApi().apiAccountCreateGet.bind(this, field)
			);
		},
		async postAccountCreate(fields: AccountCreateCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new AccountApi().apiAccountCreatePost.bind(this, fields)
			);
		},
		async postEmailChange(fields: AccountEmailChangeCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new AccountApi().apiAccountEmailChangePost.bind(this, fields)
			);
		},
		async postFtuxCompleted() {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new AccountApi().apiAccountFtuxCompletedPost.bind(this)
			);
		},
		async getMessages() {
			return request<Promise<AxiosResponse<AccountMessagesResponse | ResponseStandardFail>>>(
				new AccountApi().apiAccountMessagesGet.bind(this)
			);
		},
		async postNameChange(fields: AccountNameChangeCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new AccountApi().apiAccountNameChangePost.bind(this, fields)
			);
		},
		async postPasswordChange(fields: AccountPasswordChangeCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new AccountApi().apiAccountPasswordChangePost.bind(this, fields)
			);
		},
		async getSettings() {
			return request<Promise<AxiosResponse<AccountSettingsResponse | ResponseStandardFail>>>(
				new AccountApi().apiAccountSettingsGet.bind(this)
			);
		},
		async getUserInfo() {
			return request<Promise<AxiosResponse<AccountUserInfoResponse | ResponseStandardFail>>>(
				new AccountApi().apiAccountUserInfoGet.bind(this)
			);
		},

		// ADMIN
		async postAdminGrant(fields: AdminGrantCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new AdminApi().apiAdminGrantPost.bind(this, fields)
			);
		},
		async postAdminRevoke(fields: AdminRevokeCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new AdminApi().apiAdminRevokePost.bind(this, fields)
			);
		},

		// AUTHENTICATION
		async postChangePassword(fields: AuthenticationChangePasswordCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new AuthenticationApi().apiAuthenticationChangePasswordPost.bind(this, fields)
			);
		},
		async postForgotPassword(fields: AuthenticationForgotPasswordCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new AuthenticationApi().apiAuthenticationForgotPasswordPost.bind(this, fields)
			);
		},
		async postLogin(fields: AuthenticationLoginCommand) {
			destroyToken();
			return request<Promise<AxiosResponse<AuthenticationLoginResponse | ResponseStandardFail>>>(
				new AuthenticationApi().apiAuthenticationLoginPost.bind(this, fields)
			);
		},
		async postResetPassword(fields: AuthenticationResetPasswordCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new AuthenticationApi().apiAuthenticationResetPasswordPost.bind(this, fields)
			);
		},
		async postLogout() {
			destroyToken();
		},
		async postAuthenticateToken(fields: AuthenticationTokenCommand) {
			return request<Promise<AxiosResponse<AuthenticationTokenResponse | ResponseStandardFail>>>(
				new AuthenticationApi().apiAuthenticationTokenPost.bind(this, fields)
			);
		},

		// BOOKS
		// NOTE: Do not use, endpoint will be deleted later by BED
		// async getBookBooks() {
		// 	return request<Promise<AxiosResponse<BookBooksResponse | ResponseStandardFail>>>(
		// 		new BookApi().apiBookBooksGet.bind(this)
		// 	);
		// },
		//
		async postBookFavorite(fields: BookFavoriteCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new BookApi().apiBookFavoritePost.bind(this, fields)
			);
		},
		async getBookList() {
			return request<Promise<AxiosResponse<BookListResponse | ResponseStandardFail>>>(
				new BookApi().apiBookListGet.bind(this)
			);
		},
		async getBookStages() {
			return request<Promise<AxiosResponse<BookStagesResponse | ResponseStandardFail>>>(
				new BookApi().apiBookStagesGet.bind(this)
			);
		},

		// CLASS
		async postClassAdd(fields: ClassCreateCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandIdResponse | ResponseStandardFail>>>(
				new ClassApi().apiClassCreatePost.bind(this, fields)
			);
		},
		async deleteClassDelete(fields: ClassDeleteCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new ClassApi().apiClassDeleteDelete.bind(this, fields)
			);
		},
		async getClassList() {
			return request<Promise<AxiosResponse<ClassListResponse | ResponseStandardFail>>>(
				new ClassApi().apiClassListGet.bind(this)
			);
		},
		async getClassView(classId?: string) {
			return request<Promise<AxiosResponse<ClassViewResponse | ResponseStandardFail>>>(
				new ClassApi().apiClassViewGet.bind(this, classId)
			);
		},

		// HELP CENTRE
		async getHelpCentreList() {
			return request<Promise<AxiosResponse<HelpCentreListResponse | ResponseStandardFail>>>(
				new HelpCentreApi().apiHelpCentreListGet.bind(this)
			);
		},

		// ORGANISATIONS
		async getOrganisationBillingEdit() {
			return request<Promise<AxiosResponse<OrganisationBillingEditResponse | ResponseStandardFail>>>(
				new OrganisationApi().apiOrganisationBillingEditGet.bind(this)
			);
		},
		async postOrganisationBillingEdit(fields: OrganisationBillingEditCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new OrganisationApi().apiOrganisationBillingEditPost.bind(this, fields)
			);
		},
		async postOrganisationCancel(fields: OrganisationCancelCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new OrganisationApi().apiOrganisationCancelPost.bind(this, fields)
			);
		},
		async getOrganisationEdit() {
			return request<Promise<AxiosResponse<OrganisationEditResponse | ResponseStandardFail>>>(
				new OrganisationApi().apiOrganisationEditGet.bind(this)
			);
		},
		async postOrganisationEdit(fields: OrganisationEditCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new OrganisationApi().apiOrganisationEditPost.bind(this, fields)
			);
		},
		async getOrganisationInviteLeft() {
			return request<Promise<AxiosResponse<OrganisationInviteLeftResponse | ResponseStandardFail>>>(
				new OrganisationApi().apiOrganisationInviteLeftGet.bind(this)
			);
		},
		async postOrganisationLogo(file: FormDataEntryValue) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				// API expects a string rather than FormDataEntryValue
				new OrganisationApi().apiOrganisationLogoPost.bind(this, file as string)
			);
		},
		async postOrganisationReactivate(fields: OrganisationReactivateCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new OrganisationApi().apiOrganisationReactivatePost.bind(this, fields)
			);
		},
		// either 'id' or 'trialId'
		async getOrganisationRegister(organisationId: string, trialId: string) {
			return request<Promise<AxiosResponse<OrganisationRegisterQueryResponse | ResponseStandardFail>>>(
				new OrganisationApi().apiOrganisationRegisterGet.bind(this, organisationId, trialId)
			);
		},
		async postOrganisationRegister(fields: OrganisationRegisterCommand) {
			return request<Promise<AxiosResponse<OrganisationRegisterCommandResponse | ResponseStandardFail>>>(
				new OrganisationApi().apiOrganisationRegisterPost.bind(this, fields)
			);
		},
		async getOrganisationRegisterComplete(field: string) {
			return request<Promise<AxiosResponse<OrganisationRegisterCompleteResponse | ResponseStandardFail>>>(
				new OrganisationApi().apiOrganisationRegisterCompleteGet.bind(this, field)
			);
		},
		async postOrganisationRevokePending() {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new OrganisationApi().apiOrganisationRevokePendingPost.bind(this)
			);
		},
		async getOrganisationSettings() {
			return request<Promise<AxiosResponse<OrganisationSettingsResponse | ResponseStandardFail>>>(
				new OrganisationApi().apiOrganisationSettingsGet.bind(this)
			);
		},

		// PAYMENT
		async postPaymentCalculate(fields: PaymentCalculateCommand) {
			return request<Promise<AxiosResponse<PaymentCalculateResponse | ResponseStandardFail>>>(
				new PaymentApi().apiPaymentCalculatePost.bind(this, fields)
			);
		},
		async postPaymentCalculateUpgrade(fields: PaymentCalculateUpgradeCommand) {
			return request<Promise<AxiosResponse<PaymentCalculateUpgradeResponse | ResponseStandardFail>>>(
				new PaymentApi().apiPaymentCalculateUpgradePost.bind(this, fields)
			);
		},
		async getPaymentUpdateDetails() {
			return request<Promise<AxiosResponse<PaymentUpdateDetailsResponse | ResponseStandardFail>>>(
				new PaymentApi().apiPaymentUpdateDetailsGet.bind(this)
			);
		},
		async postPaymentWebhook() {
			return request<Promise<AxiosResponse<void | ResponseStandardFail>>>(
				new PaymentApi().apiPaymentWebHookPost.bind(this)
			);
		},

		// PLANNER
		async getPlannerWeek(field?: string) {
			return request<Promise<AxiosResponse<PlannerWeekResponse | ResponseStandardFail>>>(
				new PlannerApi().apiPlannerWeekGet.bind(this, field)
			);
		},
		async getPlannerWeekFind(year?: string, stage?: string, week?: string) {
			return request<Promise<AxiosResponse<PlannerWeekFindResponse | ResponseStandardFail>>>(
				new PlannerApi().apiPlannerWeekFindGet.bind(this, year, stage, week)
			);
		},

		// PROFESSIONAL LEARNING
		async getProfessionalLearningList() {
			return request<Promise<AxiosResponse<ProfessionalLearningListResponse | ResponseStandardFail>>>(
				new ProfessionalLearningApi().apiProfessionalLearningListGet.bind(this)
			);
		},

		// RESOURCE
		async getResourceList() {
			return request<Promise<AxiosResponse<ResourceListResponse | ResponseStandardFail>>>(
				new ResourceApi().apiResourceListGet.bind(this)
			);
		},
		async postResourceFavorite(fields: ResourceFavoriteCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new ResourceApi().apiResourceFavoritePost.bind(this, fields)
			);
		},

		// ROUTINE
		async getRoutineGroups() {
			return request<Promise<AxiosResponse<RoutineGroupsResponse | ResponseStandardFail>>>(
				new RoutineApi().apiRoutineGroupsGet.bind(this)
			);
		},
		async getRoutineRoutines(id: string) {
			return request<Promise<AxiosResponse<RoutineRoutinesResponse | ResponseStandardFail>>>(
				new RoutineApi().apiRoutineRoutinesGet.bind(this, id)
			);
		},
		async getRoutineView({ id, dayId, currentRoutine }: { id: string; dayId?: string; currentRoutine?: number }) {
			return request<Promise<AxiosResponse<RoutineViewResponse | ResponseStandardFail>>>(
				new RoutineApi().apiRoutineViewGet.bind(this, id, dayId, currentRoutine)
			);
		},

		// STAFF
		async postStaffBookComplete(fields: StaffBookCompleteCommand) {
			return request<Promise<AxiosResponse<StaffBookCompleteResponse | ResponseStandardFail>>>(
				new StaffApi().apiStaffBookCompletePost.bind(this, fields)
			);
		},
		async getStaffBookView(field: string) {
			return request<Promise<AxiosResponse<StaffBookViewResponse | ResponseStandardFail>>>(
				new StaffApi().apiStaffBookViewGet.bind(this, field)
			);
		},
		async postStaffClassAssign(fields: StaffClassAssignCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new StaffApi().apiStaffClassAssignPost.bind(this, fields)
			);
		},
		async deleteStaffClassRemove(fields: StaffClassRemoveCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new StaffApi().apiStaffClassRemoveDelete.bind(this, fields)
			);
		},
		async getStaffDashboard() {
			return request<Promise<AxiosResponse<StaffDashboardResponse | ResponseStandardFail>>>(
				new StaffApi().apiStaffDashboardGet.bind(this)
			);
		},
		async deleteStaffDelete(fields: StaffDeleteCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new StaffApi().apiStaffDeleteDelete.bind(this, fields)
			);
		},
		async getInvitesLeft() {
			return request<Promise<AxiosResponse<OrganisationInviteLeftResponse | ResponseStandardFail>>>(
				new OrganisationApi().apiOrganisationInviteLeftGet.bind(this)
			);
		},
		async postStaffInvite(fields: StaffInviteCommand) {
			return request<Promise<AxiosResponse<StaffInviteResponse | ResponseStandardFail>>>(
				new StaffApi().apiStaffInvitePost.bind(this, fields)
			);
		},
		async postStaffInviteResend(fields: StaffInviteResendCommand) {
			return request<Promise<AxiosResponse<StaffInviteResponse | ResponseStandardFail>>>(
				new StaffApi().apiStaffInviteResendPost.bind(this, fields)
			);
		},
		async deleteStaffInviteRevoke(fields: StaffInviteRevokeCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new StaffApi().apiStaffInviteRevokeDelete.bind(this, fields)
			);
		},
		async postStaffUpload(field: File | string) {
			return request<Promise<AxiosResponse<StaffUploadResponse | ResponseStandardFail>>>(
				new StaffApi().apiStaffUploadPost.bind(this, field as string)
			);
		},
		async getStaffView(fields: string) {
			return request<Promise<AxiosResponse<StaffViewResponse | ResponseStandardFail>>>(
				new StaffApi().apiStaffViewGet.bind(this, fields)
			);
		},

		async getStaffList() {
			return request<Promise<AxiosResponse<StaffListResponse | ResponseStandardFail>>>(
				new StaffApi().apiStaffListGet.bind(this)
			);
		},

		// STUDENT
		async postStudentActivityProgress(fields: StudentActivityProgressCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new StudentApi().apiStudentActivityProgressPost.bind(this, fields)
			);
		},
		async postStudentActivityStart(fields: StudentActivityStartCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse>>>(
				new StudentApi().apiStudentActivityStartPost.bind(this, fields)
			);
		},
		async getStudentAwards() {
			return request<Promise<AxiosResponse<StudentAwardsResponse | ResponseStandardFail>>>(
				new StudentApi().apiStudentAwardsGet.bind(this)
			);
		},
		async postStudentBookComplete(fields: StudentBookCompleteCommand) {
			return request<Promise<AxiosResponse<StudentBookCompleteResponse | ResponseStandardFail>>>(
				new StudentApi().apiStudentBookCompletePost.bind(this, fields)
			);
		},
		async postStudentBookProgress(fields: StudentBookProgressCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new StudentApi().apiStudentBookProgressPost.bind(this, fields)
			);
		},
		async postStudentBookRating(fields: StudentBookRatingCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new StudentApi().apiStudentBookRatingPost.bind(this, fields)
			);
		},
		async getStudentBookView(field: string) {
			return request<Promise<AxiosResponse<StudentBookViewResponse | ResponseStandardFail>>>(
				new StudentApi().apiStudentBookViewGet.bind(this, field)
			);
		},
		async getStudentLibrary() {
			return request<Promise<AxiosResponse<StudentLibraryResponse | ResponseStandardFail>>>(
				new StudentApi().apiStudentLibraryGet.bind(this)
			);
		},

		// STUDENT ADMIN
		async deleteStudentAdminArchive(fields: StudentAdminArchiveCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new StudentAdminApi().apiStudentAdminArchiveDelete.bind(this, fields)
			);
		},
		async getStudentAdminArchived() {
			return request<Promise<AxiosResponse<StudentAdminArchivedResponse | ResponseStandardFail>>>(
				new StudentAdminApi().apiStudentAdminArchivedGet.bind(this)
			);
		},
		async postStudentAdminBookAssign(fields: StudentAdminBookAssignCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new StudentAdminApi().apiStudentAdminBookAssignPost.bind(this, fields)
			);
		},
		async postStudentAdminBookUnassign(fields: StudentAdminBookUnassignCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new StudentAdminApi().apiStudentAdminBookUnassignPost.bind(this, fields)
			);
		},
		async postStudentAdminClassSwap(fields: StudentAdminClassSwapCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new StudentAdminApi().apiStudentAdminClassSwapPost.bind(this, fields)
			);
		},
		async postStudentAdminCreate(fields: StudentAdminCreateCommand) {
			return request<Promise<AxiosResponse<StudentAdminCreateResponse | ResponseStandardFail>>>(
				new StudentAdminApi().apiStudentAdminCreatePost.bind(this, fields)
			);
		},
		async getStudentAdminDashboard() {
			return request<Promise<AxiosResponse<StudentAdminDashboardResponse | ResponseStandardFail>>>(
				new StudentAdminApi().apiStudentAdminDashboardGet.bind(this)
			);
		},
		async deleteStudentAdminDelete(fields: StudentAdminDeleteCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new StudentAdminApi().apiStudentAdminDeleteDelete.bind(this, fields)
			);
		},
		async postStudentAdminUnarchive(fields: StudentAdminUnarchiveCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new StudentAdminApi().apiStudentAdminUnarchivePost.bind(this, fields)
			);
		},
		async postStudentAdminUpload(file?: any, classId?: string) {
			return request<Promise<AxiosResponse<StudentAdminUploadResponse | ResponseStandardFail>>>(
				new StudentAdminApi().apiStudentAdminUploadPost.bind(this, file, classId)
			);
		},
		async getStudentAdminView(field: string) {
			return request<Promise<AxiosResponse<StudentAdminViewResponse | ResponseStandardFail>>>(
				new StudentAdminApi().apiStudentAdminViewGet.bind(this, field)
			);
		},
		async postStudentAdminCards(fields: StudentAdminCardsCommand) {
			return request<Promise<AxiosResponse<StudentAdminCardsResponse | ResponseStandardFail>>>(
				new StudentAdminApi().apiStudentAdminCardsPost.bind(this, fields)
			);
		},

		// SUBSCRIPTION
		async getSubscriptionChange() {
			return request<Promise<AxiosResponse<SubscriptionChangeQueryResponse | ResponseStandardFail>>>(
				new SubscriptionApi().apiSubscriptionChangeGet.bind(this)
			);
		},
		async postSubscriptionChange(fields: SubscriptionChangeCommand) {
			return request<Promise<AxiosResponse<SubscriptionChangeCommandResponse>>>(
				new SubscriptionApi().apiSubscriptionChangePost.bind(this, fields)
			);
		},
		async postSubscriptionChangeApply(fields: SubscriptionChangeApplyCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse>>>(
				new SubscriptionApi().apiSubscriptionChangeApplyPost.bind(this, fields)
			);
		},
		async postSubscriptionEdit(fields: SubscriptionEditCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new SubscriptionApi().apiSubscriptionEditPost.bind(this, fields)
			);
		},
		async getSubscriptionList() {
			return request<Promise<AxiosResponse<SubscriptionListResponse | ResponseStandardFail>>>(
				new SubscriptionApi().apiSubscriptionListGet.bind(this)
			);
		},
		async getSubscriptionReactivateCancelled() {
			return request<Promise<AxiosResponse<SubscriptionReactivateCancelledQueryResponse | ResponseStandardFail>>>(
				new SubscriptionApi().apiSubscriptionReactivateCancelledGet.bind(this)
			);
		},
		async postSubscriptionReactivateCancelled(fields: SubscriptionReactivateCancelledCommand) {
			return request<Promise<AxiosResponse<SubscriptionReactivateCancelledCommandResponse | ResponseStandardFail>>>(
				new SubscriptionApi().apiSubscriptionReactivateCancelledPost.bind(this, fields)
			);
		},
		async postSubscriptionReactivateCancelledApply(fields: SubscriptionReactivateCancelledApplyCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new SubscriptionApi().apiSubscriptionReactivateCancelledApplyPost.bind(this, fields)
			);
		},

		// SYSTEM
		async getSystemInfo() {
			return request<Promise<AxiosResponse<SystemInfoType>>>(new SystemApi().apiSystemInfoGet.bind(this));
		},

		// TRIAL
		async postTrialCreate(fields: TrialCreateCommand) {
			return request<Promise<AxiosResponse<SharedModelsCommandResponse | ResponseStandardFail>>>(
				new TrialApi().apiTrialCreatePost.bind(this, fields)
			);
		},
		async postTrialRequest(fields: TrialRequestCommand) {
			return request<Promise<AxiosResponse<TrialRequestResponse | ResponseStandardFail>>>(
				new TrialApi().apiTrialRequestPost.bind(this, fields)
			);
		}
	};
};

export default apibridge();
