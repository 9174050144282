import { FC, useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../store/slice';

// PACKAGES
import { FieldArray, FormikProps } from 'formik';

// COMPONENTS
import Chip from './Chip';

// UTILS
import { StaffInviteEmail } from '../../api/models';
import { UserType } from '../../store/slice/User';

// TYPES
import { InviteTeachersFormValuesType } from '../../pages/TeacherAdmin/InviteTeachers';

interface MultiInputPillsTypes {
	formik: FormikProps<InviteTeachersFormValuesType>;
	remainingInvites?: number;
}

const MultiInputPills: FC<MultiInputPillsTypes> = ({ formik, remainingInvites }) => {
	const [inputValue, setInputValue] = useState('');
	const [invitesRemaining, setInvitesRemaining] = useState(0);
	const inputRef = useRef<HTMLInputElement>(null);

	const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

	const user: UserType = useAppSelector((state) => state.user);

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(event.target.value);
	};

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (['Enter', 'Tab', ',', ' '].includes(e.key)) {
			e.preventDefault();

			if (inputValue === '') {
				return;
			}

			const emailsInInput: StaffInviteEmail[] = inputValue
				.split(/,|\s/) // split by comma or space
				.map((email) => ({
					emailAddress: email.trim(),
					status: !emailRegex.test(email.trim() || '') ? 'Invalid' : 'Valid'
				})); // remove leading/trailing whitespace

			formik.setFieldValue(
				'emails',
				[...formik.values.emails, ...emailsInInput].filter((email) => email.emailAddress !== '')
			);
			setInputValue('');
		}

		if (e.key === 'Backspace' && inputValue === '') {
			formik.setFieldValue('emails', formik.values.emails?.splice(0, (formik.values.emails?.length || 1) - 1));
		}
	};

	const handlePaste = (e: React.ClipboardEvent) => {
		e.preventDefault();

		const emailsInInput: StaffInviteEmail[] = e.clipboardData
			.getData('text')
			.split(/[, ]/g) // split by comma or space
			.map((email) => ({
				emailAddress: email.trim().replace(',', ''), // remove any commas
				status: !emailRegex.test(email.trim().replace(',', '') || '') ? 'Invalid' : 'Valid'
			})); // remove leading/trailing whitespace

		formik.setFieldValue(
			'emails',
			[...formik.values.emails, ...emailsInInput].filter((email) => email.emailAddress !== '')
		);
		e.clipboardData.clearData();

		setInputValue('');
	};

	const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
		if (e.target.value === '') {
			return;
		}

		const newEmail: StaffInviteEmail = {
			emailAddress: e.target.value.trim(),
			status: !emailRegex.test(e.target.value.trim() || '') ? 'Invalid' : 'Valid'
		};

		formik.setFieldValue(
			'emails',
			[...formik.values.emails, newEmail].filter((email) => email.emailAddress !== '')
		);
		setInputValue('');
	};

	useEffect(() => {
		setInvitesRemaining((remainingInvites || 0) - (formik.values.emails?.length || 0));
	}, [formik.values.emails, remainingInvites]);

	return (
		<>
			<div className="input-container">
				<label htmlFor="multiple-pill-input" className="mb-2">
					<strong>Invite multiple teachers via email address</strong>
				</label>
				<div
					className={`multiple-pill-input-wrapper ${formik.errors?.emails?.length ? 'error' : ''}`.trim()}
					onClick={(e) => {
						const target = e.target as HTMLDivElement;
						if (target.classList.contains('multiple-pill-input-wrapper')) {
							inputRef.current?.focus();
						}
					}}
				>
					<FieldArray
						name="emails"
						render={(arrayHelpers) => (
							<>
								<div className="emails-container">
									{formik.values.emails?.map((email, index) => {
										return (
											<Chip
												key={index}
												formik={formik}
												remainingInvites={remainingInvites}
												validEmails={formik.values.emails.filter((item) => item.status === 'Valid')}
												email={email}
												index={index}
												arrayHelpers={arrayHelpers}
											/>
										);
									})}
									<input
										ref={inputRef}
										style={{ width: formik.values.emails.length === 0 ? '100%' : 'auto' }}
										type="text"
										id="multiple-pill-input"
										className="multiple-pill-input"
										value={inputValue}
										autoComplete="off"
										placeholder={
											formik.values.emails.length !== 0 ? '' : 'E.g. john@email.com, jane@email.com, sally@email.com...'
										}
										onPaste={(e) => handlePaste(e)}
										onChange={(e) => handleInputChange(e)}
										onKeyDown={(e) => handleKeyDown(e)}
										onBlur={(e) => handleBlur(e)}
									/>
								</div>
							</>
						)}
					/>
				</div>
				<div className="d-flex justify-content-between gap-3 mt-2">
					<p className="text-shades-500 fw-semibold body-small mb-0">
						Please separate multiple emails with a space or comma.
					</p>
					{user.permissions?.includes('OrganisationInviteLeft') && invitesRemaining !== Infinity && (
						<p
							className={`text-shades-500 fw-bold body-small mb-0 text-end ${invitesRemaining < 0 ? 'text-error' : ''}`}
						>
							{invitesRemaining < 0 ? 0 : invitesRemaining} {invitesRemaining === 1 ? 'invitation' : 'invitations'} left{' '}
							{invitesRemaining < 0 ? `, remove ${Math.abs(invitesRemaining)} excess emails` : ''}
						</p>
					)}
				</div>
			</div>
		</>
	);
};

export default MultiInputPills;
