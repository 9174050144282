import { FC, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/slice';
import { ToastMessageType, ToastMessagesSlice } from '../../store/slice/ToastMessages';
import { UserSlice } from '../../store/slice/User';

// PACKAGES
import { Form, Formik, FormikProps } from 'formik';
import { useNavigate } from 'react-router-dom';

// API
import apibridge from '../../apibridge';
import { StaffUploadEmail } from '../../api/models';

// COMPONENTS
import TitleSection from '../../components/TitleSection/TitleSection';
import MultiInputPills from '../../components/MultiInputPills/MultiInputPills';
import FileTeacherImport from '../../components/FileImport/FileTeacherImport';
import Divider from '../../components/Divider/Divider';
import HintMessage from '../../components/HintMessage/HintMessage';

// UTILS
import { guid } from '../../libs/utils';

export type InviteTeachersFormValuesType = {
	emails: StaffUploadEmail[];
};

const InviteTeachers: FC = () => {
	const [remainingInvites, setRemainingInvites] = useState(0);
	const ToastMessages = useAppSelector((state) => state.toastMessages);
	const user = useAppSelector((state) => state.user);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [submittingTeacherImport, setSubmittingTeacherImport] = useState(false);
	const [submittingTeachers, setSubmittingTeachers] = useState(false);
	const disableSubmitButton = remainingInvites <= 0 || submittingTeacherImport || submittingTeachers;

	const errorId = 'email-error';

	const errorHasShown = useRef(false);
	const formikRef = useRef<FormikProps<InviteTeachersFormValuesType>>(null);
	const prevErrorLength = useRef(0);

	const getInvitesLeft = async () => {
		const response = await apibridge.getInvitesLeft();
		if (response && response.data && !response.data.isError && response.data.result) {
			const { invitesLeft = 0, isUnilimited } = response.data.result;
			setRemainingInvites(isUnilimited ? Infinity : invitesLeft);
		}
	};

	useEffect(() => {
		getInvitesLeft();
		// eslint-disable-next-line
	}, []);

	const initialValues: InviteTeachersFormValuesType = {
		emails: []
	};

	const validateEmails = (values: InviteTeachersFormValuesType) => {
		try {
			const errors: InviteTeachersFormValuesType = { emails: [] };
			const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
			const seenEmails: Record<string, number> = {};

			if (values.emails === null || values.emails === undefined) {
				throw new Error('Invalid value for property: values.emails');
			}

			values.emails.forEach((email, index) => {
				if (email === null || email === undefined) {
					throw new Error(`Email is null at index ${index}`);
				}

				const emailAddress = email.emailAddress || '';

				if (!emailRegex.test(emailAddress)) {
					errors.emails[index] = {
						...(email || {}),
						status: 'Invalid'
					};
				} else if (seenEmails[emailAddress.toLowerCase()] !== undefined) {
					errors.emails[seenEmails[emailAddress.toLowerCase()]] = {
						...(values.emails[seenEmails[emailAddress]] || {}),
						status: 'Invalid'
					};

					errors.emails[index] = {
						...(email || {}),
						status: 'Invalid'
					};
				} else {
					seenEmails[emailAddress.toLowerCase()] = index;
				}

				if (email.status !== 'Valid') {
					errors.emails[index] = {
						...(email || {}),
						status: 'Invalid'
					};
				}
			});

			return errors;
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const submitInvites = async (values: InviteTeachersFormValuesType) => {
		setSubmittingTeachers(true);

		const { emails } = values;
		const emailsToSend: string[] = [];
		emails.forEach((email) => {
			if (email.emailAddress !== undefined) {
				emailsToSend.push(email.emailAddress);
			}
		});

		const response = await apibridge.postStaffInvite({ emails: emailsToSend });
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				if (response.data.result?.profilesCreated) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'success',
							heading: `${emailsToSend.length === 1 ? 'Invite' : 'Invites'} sent`,
							description: `${
								emailsToSend.length === 1 ? 'An invite has' : 'The invites have'
							} now been sent to your nominated email ${emailsToSend.length === 1 ? 'address' : 'addresses'}.`
						})
					);
					// also getting updated User object after so can determine whether to show the 'Add Teachers' dot anymore
					if (!user.profiles?.[0].staffAdded) {
						dispatch(UserSlice.actions.forceUpdate());
					}
					navigate('/teacher-management');
				} else {
					if (response.data.result.emails?.some((email) => email.status === 'ProfileExists')) {
						const message: ToastMessageType = {
							id: guid(),
							type: 'danger',
							heading: 'Existing teachers found',
							description:
								'It appears you have added teacher emails that already exist within your organisation. Please remove these email addresses to continue.'
						};
						dispatch(ToastMessagesSlice.actions.add(message));
					}

					if (response.data.result.emails?.some((email) => email.status === 'ProfileExistsDifferentOrganisation')) {
						const message: ToastMessageType = {
							id: guid(),
							type: 'danger',
							heading: 'Existing teachers found',
							description:
								'It seems like the teacher you are trying to add exists in another organisation in the platform. A teacher can only be connected to one organisation at a time.'
						};
						dispatch(ToastMessagesSlice.actions.add(message));
					}

					let updatedEmailList: InviteTeachersFormValuesType = { emails: [] };

					response.data.result?.emails?.map((email) => {
						return (updatedEmailList = {
							...updatedEmailList,
							emails: [
								...updatedEmailList.emails,
								{
									emailAddress: email.emailAddress,
									status: email.status !== 'Valid' ? 'Invalid' : 'Valid'
								}
							]
						});
					});

					formikRef.current?.setFieldValue('emails', updatedEmailList.emails);
				}
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Staff invite error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setSubmittingTeachers(false);
	};

	const checkError = () => {
		if (formikRef.current) {
			const errorLength = formikRef.current.errors.emails?.length;

			if (errorLength && errorLength > prevErrorLength.current) {
				prevErrorLength.current = errorLength;

				const hasExistingTeachersFoundError = ToastMessages.find((message) =>
					message.heading.includes('Existing teachers found')
				);

				if (
					!ToastMessages.find((message) => message.id === errorId) &&
					!errorHasShown.current &&
					!hasExistingTeachersFoundError
				) {
					const message: ToastMessageType = {
						id: errorId,
						type: 'danger',
						heading: 'Invalid email addresses added',
						description: 'Please remove any invalid email addresses to continue sending invitations.'
					};
					dispatch(ToastMessagesSlice.actions.add(message));
				}
			} else {
				prevErrorLength.current = errorLength || 0;
			}
		}
	};

	return (
		<div className="invite-teachers-page d-flex flex-column flex-grow-1">
			<TitleSection
				title="Invite teachers"
				description="Invite teachers and staff to join the organisation."
			></TitleSection>
			<section className="container flex-grow-1">
				<div className="row h-100 row-gap-5">
					<div className="col">
						<Formik
							initialValues={initialValues}
							validateOnMount
							enableReinitialize
							validate={validateEmails}
							validateOnBlur
							onSubmit={() => {}}
							innerRef={formikRef}
						>
							{(formik) => {
								setTimeout(checkError, 0);
								return (
									<Form className="d-flex flex-column h-100">
										<div className="flex-grow-1">
											<MultiInputPills formik={formik} remainingInvites={remainingInvites} />
											<Divider />
											<FileTeacherImport
												submittingTeacherImport={submittingTeacherImport}
												setSubmittingTeacherImport={setSubmittingTeacherImport}
												formik={formik}
											/>
										</div>
										<div className="button-group d-flex gap-4">
											<button type="button" className="btn btn-lg btn-white w-50" onClick={() => window.history.back()}>
												Cancel
											</button>
											<button
												type="button"
												className="btn btn-lg w-50"
												disabled={
													disableSubmitButton || formik.values.emails.length === 0 || formik.errors.emails?.length !== 0
												}
												onClick={() => submitInvites(formik.values)}
											>
												Send invitations
											</button>
										</div>
									</Form>
								);
							}}
						</Formik>
					</div>
					<div className="col-lg-4 offset-lg-1">
						{/* <p> is for only for vertical-alignment with the left-side input, which also has a text element */}
						<p className="mb-2 invisible pe-none">-</p>
						<HintMessage
							title="What next?"
							message="Once you've sent invitations to your teachers, they will receive an email enabling them to join your organisation."
						/>
					</div>
				</div>
			</section>
		</div>
	);
};

export default InviteTeachers;
