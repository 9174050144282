import { FC, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ToastMessagesSlice } from '../../store/slice/ToastMessages';

// PACKAGES
import { Fade, Modal, Offcanvas, Spinner } from 'react-bootstrap';

// COMPONENTS
import { EmbeddedYouTubeOrVimeoVideo } from '../../components/_Helpers/Video';
import ImgRem from '../../libs/imgRem';

// UTILS
import SvgMask from '../../components/_Helpers/SvgMask';
import CroppedImage, { generatedCropUrl } from '../../components/_Helpers/CroppedImage';
import { useAudioPlayer } from '../../libs/customHooks';
import { guid, preloadImage } from '../../libs/utils';
import DelayedFadeIn from '../../components/_Helpers/DelayedFadeIn';

// TYPES
import apibridge from '../../apibridge';
import { RoutineViewRoutineItem, RoutineViewRoutineResult } from '../../api/models';

const RoutineItemPage: FC = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const searchParams = new URLSearchParams(location.search);
	const dayId = searchParams.get('dayId') || undefined;

	const splitLocation = location.pathname.split('/');
	const isPlansRoute = splitLocation[1] === 'plans';
	const routineIdUrlPart = isPlansRoute ? splitLocation[3] : splitLocation[2];
	const routineNumberUrlPart = isPlansRoute ? splitLocation[4] || '1' : splitLocation[3] || '1';

	const [isLoadingData, setIsLoadingData] = useState(true);
	const [isPreloadingImages, setIsPreloadingImages] = useState(false);
	const [loadedRoutineImageTotal, setLoadedRoutineImageTotal] = useState(0);

	const [hasMounted, setHasMounted] = useState(false);
	const [routineItemPageData, setRoutineItemPageData] = useState<RoutineViewRoutineResult>();
	const [showRoutineInfoOffCanvas, setShowRoutineInfoOffCanvas] = useState(false);
	const [showReturnToPlanModal, setShowReturnToPlanModal] = useState(false);
	const [currentRoutineIndex, setCurrentRoutineIndex] = useState(0);

	const { dayInformation } = routineItemPageData || {};

	const btnPrevRef = useRef<HTMLAnchorElement>(null);
	const btnNextRef = useRef<HTMLAnchorElement>(null);

	const resolvedPageIndex = parseInt(routineNumberUrlPart) - 1;
	const currentRoutineItemPage = routineItemPageData?.items?.[resolvedPageIndex];
	const isFirstPage = resolvedPageIndex === 0;
	const isLastPage = resolvedPageIndex === (routineItemPageData?.items?.length || 1) - 1;
	const isImageItem = currentRoutineItemPage?.type === 'ImageItem';
	const isVideoItem = currentRoutineItemPage?.type === 'VideoItem';

	const audioPlayer = useAudioPlayer();
	const { addToAudioQueue, clearAudioQueue, getCurrentAudioClip } = audioPlayer;
	const currentAudioClip = getCurrentAudioClip();

	const hasAudio = currentRoutineItemPage?.image?.audioUrls?.length;
	const routineAudioIsPlaying =
		hasAudio &&
		currentRoutineItemPage.image?.audioUrls?.some((audioItem) => audioItem === currentAudioClip?.audioProps.url);

	const routineImgWidth = 1280;

	const getPrevNextLinks = () => {
		const prevRoutineIndex = (dayInformation?.currentRoutine || 1) - 1;
		const nextRoutineIndex = (dayInformation?.currentRoutine || 1) + 1;
		// minus 1 because currentRoutine is 1-based, but the array is 0-based
		const prevRoutineId = routineItemPageData?.dayInformation?.routines?.[prevRoutineIndex - 1];
		const nextRoutineId = routineItemPageData?.dayInformation?.routines?.[nextRoutineIndex - 1];

		return {
			prevSlideLink: `../${parseInt(routineNumberUrlPart) - 1}${location.search}`,
			nextSlideLink: `../${parseInt(routineNumberUrlPart) + 1}${location.search}`,
			// can only ever be accessed via /plans/routines
			prevRoutineLink: `/plans/routines/${prevRoutineId}/1?dayId=${dayId}`,
			nextRoutineLink: `/plans/routines/${nextRoutineId}/1?dayId=${dayId}`
		};
	};

	const getRoutineItemPageData = async () => {
		const response = await apibridge.getRoutineView({
			id: encodeURIComponent(routineIdUrlPart),
			dayId,
			currentRoutine: currentRoutineIndex + 1
		});
		if (response && response.data) {
			if (!response.data.isError && response.data.result) {
				// if has a videoId, create a new page and insert it at the start of the page array data
				const { videoId } = response.data.result;

				if (videoId) {
					const randomId = guid();
					const newVideoItem: RoutineViewRoutineItem = {
						id: randomId,
						type: 'VideoItem',
						video: {
							id: randomId,
							videoId
						}
					};

					response.data.result.items?.unshift(newVideoItem);
				}

				// If there's a Title Slide, create a new page and insert it at the start of the page array data AND Before the Video if it exists.
				const { titleSlideUrl } = response.data.result;

				if (titleSlideUrl) {
					const randomId = guid();
					const newImageItem: RoutineViewRoutineItem = {
						id: randomId,
						type: 'ImageItem',
						image: {
							id: randomId,
							imageUrl: titleSlideUrl
						}
					};

					response.data.result.items?.unshift(newImageItem);
				}

				setRoutineItemPageData(response.data.result);

				// am preloading all routine images for a snappier experience... (LLCAT-125)
				// using location.state so the preloader doesn't trigger between pages of same routine
				if (!location.state?.routineImagesLoaded && response.data.result.items?.length) {
					setIsLoadingData(false);
					setIsPreloadingImages(true);

					const imageUrls = response.data.result.items
						?.filter((item) => item.type === 'ImageItem' && item.image?.imageUrl)
						.map((item) => item.image?.imageUrl || '');
					setLoadedRoutineImageTotal(response.data.result.items.length - imageUrls.length);

					const cropUrls = imageUrls.map((imgUrl) => {
						// cropUrl we're preloading should match what gets loaded on the page, otherwise no point
						return generatedCropUrl({ isWebP: true, src: imgUrl, width: routineImgWidth });
					});
					await Promise.all(
						cropUrls.map((cropUrl) =>
							preloadImage({
								imageSrc: cropUrl
							}).then(() => setLoadedRoutineImageTotal((total) => total + 1))
						)
					);

					setIsPreloadingImages(false);
				}
			} else if (response.data.validationErrors) {
				for (const err of response.data.validationErrors) {
					dispatch(
						ToastMessagesSlice.actions.add({
							id: guid(),
							type: 'danger',
							heading: 'Routines error',
							description: err.reason || 'Unknown error'
						})
					);
				}
			}
		}

		setIsLoadingData(false);
		setHasMounted(true);
	};

	const handleKeyDown = (e: KeyboardEvent) => {
		if (e.key === 'ArrowLeft') btnPrevRef.current?.click();
		if (e.key === 'ArrowRight') btnNextRef.current?.click();
	};

	useEffect(() => {
		if (hasMounted) document.addEventListener('keydown', handleKeyDown);
		return () => document.removeEventListener('keydown', handleKeyDown);
	}, [hasMounted]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		getRoutineItemPageData();
	}, [currentRoutineIndex]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div
			className={`page-routine-item-page d-flex flex-column flex-grow-1 ${hasAudio ? 'has-audio' : ''} ${
				routineAudioIsPlaying ? 'audio-playing' : ''
			}`}
		>
			<header>
				{isPlansRoute ? (
					dayId ? (
						<button type="button" onClick={() => setShowReturnToPlanModal(true)} className="btn btn-home-corner">
							<i></i>
							<span className="visually-hidden">Home</span>
						</button>
					) : (
						<Link to="/plans" className="btn btn-home-corner">
							<i></i>
							<span className="visually-hidden">Home</span>
						</Link>
					)
				) : (
					<Link to="/routines" className="btn btn-home-corner">
						<i></i>
						<span className="visually-hidden">Home</span>
					</Link>
				)}
				<div className="logo-wrapper d-flex align-items-center justify-content-center">
					<Link to="/" className="no-pseudo-hover">
						<ImgRem
							src="/svg/llll-online-contained-wide-logo.svg"
							width="172"
							height="56"
							alt="Little Learners Love Literacy Online logo"
						/>
					</Link>
				</div>
				<button type="button" className="btn btn-top-right" onClick={() => setShowRoutineInfoOffCanvas(true)}>
					<SvgMask path="/svg/info-solid.svg" width={48} height={48} />
					<span className="visually-hidden">Info</span>
				</button>
			</header>
			<div className="d-flex flex-column align-items-center justify-content-center flex-grow-1 mb-4">
				{isLoadingData ? (
					<DelayedFadeIn>
						<Spinner />
					</DelayedFadeIn>
				) : (
					<div className="container d-flex flex-column justify-content-center flex-grow-1">
						<Fade timeout={300} in={!isPreloadingImages} mountOnEnter unmountOnExit>
							<div className="routine-item-wrapper position-relative">
								<div
									className={`routine-item position-absolute w-100 h-100 z-2 d-flex flex-column ${
										isImageItem ? 'is-image-item' : ''
									}`}
								>
									<div className="position-relative flex-grow-1">
										{currentRoutineItemPage &&
											(isImageItem ? (
												// am not including height when cropping, as we don't really know what Little Learners will upload to routines
												// Umbraco cropper can automatically crop height proportionally based on the width if height is left out
												<CroppedImage
													src={currentRoutineItemPage.image?.imageUrl || ''}
													width={routineImgWidth}
													alt=""
													className="position-absolute w-100 h-100 py-4 object-fit-scale"
												/>
											) : isVideoItem && currentRoutineItemPage.video?.videoId ? (
												<>
													<div className="position-absolute w-100 h-100 d-flex align-items-center justify-content-center">
														<p>Video loading...</p>
													</div>
													<EmbeddedYouTubeOrVimeoVideo youTubeOrVimeoId={currentRoutineItemPage.video.videoId} />
												</>
											) : null)}
									</div>

									{hasAudio && (
										<div className="position-absolute top-100 w-100 py-3 text-center">
											<button
												type="button"
												className="btn btn-audio-icon has-text"
												onClick={() => {
													if (routineAudioIsPlaying) {
														clearAudioQueue();
													} else {
														clearAudioQueue();
														for (const audioItem of currentRoutineItemPage.image?.audioUrls || []) {
															addToAudioQueue({ id: guid(), url: audioItem });
														}
													}
												}}
											>
												<span className="text">{routineAudioIsPlaying ? 'Playing...' : 'Play'}</span>
												<i></i>
											</button>
										</div>
									)}
								</div>
								<div className="buttons-wrapper position-absolute start-0 d-flex justify-content-between w-100">
									<div>
										{isFirstPage ? (
											routineItemPageData?.dayInformation?.previousRoutineId && (
												<Link
													ref={btnPrevRef}
													to={getPrevNextLinks().prevRoutineLink}
													className="btn btn-arrow-left"
													onClick={() => setCurrentRoutineIndex((idx) => idx - 1)}
													state={{
														preventScroll: true,
														routineImagesLoaded: true
													}}
												>
													<span className="visually-hidden">Previous Routine</span>
													<SvgMask path="/svg/arrow-left.svg" width={48} height={48} />
												</Link>
											)
										) : (
											<Link
												ref={btnPrevRef}
												to={getPrevNextLinks().prevSlideLink}
												className="btn btn-arrow-left"
												state={{
													preventScroll: true,
													routineImagesLoaded: true
												}}
											>
												<span className="visually-hidden">Previous</span>
												<SvgMask path="/svg/arrow-left.svg" width={48} height={48} />
											</Link>
										)}
									</div>
									<div>
										{isLastPage ? (
											routineItemPageData?.dayInformation?.nextRoutineId && (
												<Link
													ref={btnNextRef}
													to={getPrevNextLinks().nextRoutineLink}
													onClick={() => setCurrentRoutineIndex((idx) => idx + 1)}
													className="btn btn-arrow-right"
													state={{
														preventScroll: true,
														routineImagesLoaded: true
													}}
												>
													<span className="visually-hidden">Next Routine</span>
													<SvgMask path="/svg/arrow-right.svg" width={48} height={48} />
												</Link>
											)
										) : (
											<Link
												ref={btnNextRef}
												to={getPrevNextLinks().nextSlideLink}
												className="btn btn-arrow-right"
												state={{
													preventScroll: true,
													routineImagesLoaded: true
												}}
											>
												<span className="visually-hidden">Next</span>
												<SvgMask path="/svg/arrow-right.svg" width={48} height={48} />
											</Link>
										)}
									</div>
								</div>
							</div>
						</Fade>

						<Fade timeout={300} in={isPreloadingImages} mountOnEnter unmountOnExit>
							<div className="image-preloader position-absolute top-50 start-50 translate-middle z-5 text-center text-vivid-blue">
								<div className="d-flex flex-column align-items-center gap-3">
									<Spinner animation="border" role="status" className="spinner-blue-ring" />
									<div>
										<div className="h3">
											<strong>
												{loadedRoutineImageTotal} / {routineItemPageData?.items?.length}
											</strong>
										</div>
									</div>
								</div>
							</div>
						</Fade>
					</div>
				)}
			</div>

			<Offcanvas
				bsPrefix="routine-info offcanvas"
				show={showRoutineInfoOffCanvas}
				onHide={() => setShowRoutineInfoOffCanvas(false)}
				placement="end"
			>
				<Offcanvas.Header className="pb-4" closeButton></Offcanvas.Header>
				<Offcanvas.Body>
					<div className="flex-grow-1">
						{routineItemPageData?.groupName && (
							<h2 className="mb-4 text-shades-800">
								<strong>{routineItemPageData.groupName}</strong>
							</h2>
						)}
						{routineItemPageData?.notes && <div dangerouslySetInnerHTML={{ __html: routineItemPageData.notes }}></div>}
					</div>
				</Offcanvas.Body>
			</Offcanvas>

			<Modal show={showReturnToPlanModal} onHide={() => setShowReturnToPlanModal(false)} centered>
				<Modal.Body>
					<h2 className="m-0 text-shades-800">
						<strong>Exit routines?</strong>
					</h2>
					<p className="m-0">You will lose your progress and return to the plan.</p>
					<div className="button-group d-flex">
						<button onClick={() => setShowReturnToPlanModal(false)} className="btn btn-white btn-lg w-100">
							Cancel
						</button>
						<Link to="/plans" className="btn btn-primary btn-lg w-100">
							Exit
						</Link>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default RoutineItemPage;
